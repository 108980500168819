<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Datos de la Representación </h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        {{ isCoInvestment }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <pre>{{ application }}</pre>
      </v-col>
    </v-row>


    <v-row class="verde rounded mb-10 py-3 px-4">
      <v-col col md="8" class="d-flex align-center">

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-checkbox dark v-model="checkbox" :rules="rules.required" hide-details class="shrink mr-2 mt-0 strong">
            <template v-slot:label>
              <div class="white---text">
                ¿Aprobar Solicitud?
              </div>
            </template>
          </v-checkbox>
        </v-form>

      </v-col>
      <v-col col md="4">
        <v-btn
          :disabled="!valid"
          x-large
          color="dorado-obscuro"
          dark
          class="float-right ma-1"
          @click="approveApplication()"
        >
          Aprobar Solicitud solicitud
          <v-icon
            right
            dark
          >
            mdi-file-sign
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>
import ProgressComponent from '@/components/global/_Progress.vue'
import wsApplications from '@/services/application'
import wsExternal from '@/services/external'

export default {
  name: 'RmeDataComponent',
  
  components: {
  },

  data: () => ({
    valid: true,
    application: [],
    checkbox: false,

    rules: {
      required: [
        value => !!value || 'Este campo es requerido',
      ],
    },

  }),

  mounted(){
    this.loadApplication(this.uuid)
  },

  computed: {
    uuid(){
      return this.$route.params.uuid
    },
    isCoInvestment() {
      if (this.application.status) {
        var result = this.application.status.coInvestment
        return result;
      }
      return false;
    },
  },

  methods:{

    approveApplication(){
      if(this.$refs.form.validate() === true){
        console.log("🚀 ~ file: rmeData.vue:90 ~ approveApplication:", this.$refs.form.validate())
        //this.application.id
        console.log("🚀 ~ file: rmeData.vue:135 ~ approveApplication ~ this.application.id:", this.application.id)
        this.updateApplication()
      }else{
        console.log("🚀 ~ file: rmeData.vue:92 ~ approveApplication:", this.$refs.form.validate())
      }
        
    },

    loadApplication(uuid){
      wsApplications.findByUuid(uuid).then(
        response => {
          this.application = response.data.data;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementValidation: true,
          organizationData: true,
          representativeData: true,
          boardDirectors: true,
          memberRelationship: true,
          coInvestment: this.isCoInvestment ? true : false,
          inValidation: true,
          validateRme: true,
          signRme: true,
          signIme: true,
          certificate: true
        }
      }
      wsApplications.update(this.application.id, {data:data}).then(
        response => {
          this.currentApplication = response.data.data;
          console.log("🚀 ~ file: rmeData.vue:152 ~ updateApplication ~ this.currentApplication:", this.currentApplication)
          this.$router.push('/dashboard/')
        },
        error => {
          this.currentApplication =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


  },

}
</script>