<template>
  <section class="rme-data">

    <span to="/dashboard" v-if="user_role === 'SysAdmin'">SysAdmin</span>
    <span to="/dashboard" v-else-if="user_role === 'ime_certificador'">ime_certificador</span>
    <span to="/dashboard" v-else-if="user_role === 'ime_validador'">ime_validador</span>
    <span to="/dashboard" v-else-if="user_role === 'ime_observador'">ime_observador</span>
    <SignCertificatorComponent v-else-if="user_role === 'rme_certificador'" ></SignCertificatorComponent>
    <ProgressComponent v-else></ProgressComponent>

	</section>
</template>
<script>
import UserService from '@/services/user.service'
import ProgressComponent from '@/components/global/_Progress.vue'
import SignCertificatorComponent from '@/components/roles/rme_certificador/Sign.vue'

export default {
  name: 'SignCertificatorView',
  
  components: {
    ProgressComponent,
    SignCertificatorComponent
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    redirectToDashboard(){
      return this.$router.push('/dashboard/');
    }
  },

  mounted(){

    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }

  },

  methods:{

  },

}
</script>