<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Datos del Representante</h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-select
            :items="jobs"
            filled
            label="Cargo dentro de la Organización:"
            prepend-icon="mdi-account-hard-hat"
            v-model="dataForm.cargoIntegrante"
          ></v-select>
        </v-col>  
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>
        <v-col col md="4" sm="6" xs="12">
        </v-col>


      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Nombre(s): "
          prepend-icon="mdi-account"
          v-model="dataForm.nombreCompleto"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Primer Apellido: "
          prepend-icon="mdi-account"
          v-model="dataForm.apellidoPaterno"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Segundo Apellido: "
          prepend-icon="mdi-account"
          v-model="dataForm.apellidoMaterno"
        ></v-text-field>
      </v-col>



      <v-col col md="4" sm="6" xs="12" class="">
        <v-select
          :items="nationality"
          filled
          label="Nacionalidad:"
          prepend-icon="mdi-earth"
          v-model="dataForm.tipoNacionalidad"
        ></v-select>
      </v-col>

      <v-col col md="4" sm="6" xs="12" class="">
        <div class="">
          <v-file-input
            label="Documento que acredite la nacionalidad mexicana:"
            :rules="rules.uploadFile"
            @change="selectImageNacionalidad"
            v-model="currentImageNacionalidad"
            filled
            show-size
            accept="image/jpeg,image/png,application/pdf"
            ref="formUploadNac"
          ></v-file-input>
        </div>
        <div v-if="previewImageNacionalidad">
          <div v-if="currentImageNacionalidad.type === 'image/jpeg' || currentImageNacionalidad.type === 'image/png'">
            <v-img contain :lazy-src="previewImageNacionalidad" :src="previewImageNacionalidad" >
              <v-progress-linear
                v-if="loadingNac === true"
                indeterminate
                striped
                height="100%"
                value="0"
                color="green"
              ></v-progress-linear>
            </v-img>
          </div>
          <div v-else-if="currentImageNacionalidad.type === 'application/pdf'">
            <iframe :src="previewImageNacionalidad" style="height:320px;width:100%;"></iframe> 
          </div>
          <div v-else>OTRO Archivo</div>

          <v-alert v-if="alertWarningNac === true" icon="mdi-account" type="error">
            El archivo debe ser menos a 2MB
          </v-alert>

          <v-btn
            v-if="alertWarningNac === false"
            :disabled="loadedNac"
            class="mx-2 my-2 float-left"
            fab dark small
            color="yellow darken-4"
            @click="onUploadNac()"
          >
            <v-icon v-if="loadingNac === false" dark>mdi-upload</v-icon>
            <v-progress-circular
              v-if="loadingNac === true"
              icon text indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            :disabled="!loadedNac"
            class="mx-2 my-2 float-right"
            dark small
            color="red"
            @click="deleteImage(dataForm.documentoNacionalidad)"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
      </v-col>


      
      <v-col col md="4" sm="6" xs="12" class="">
        <div class="">
          <v-file-input
            label="Identificación adicional para identificar a la persona"
            :rules="rules.uploadFile"
            @change="selectImageIdentificacion"
            v-model="currentImageIdentificacion"
            filled
            show-size
            accept="image/jpeg,image/png,application/pdf"
            ref="formUploadIne"
          ></v-file-input>
        </div>
        <div v-if="previewImageIdentificacion">
          <div v-if="currentImageIdentificacion.type === 'image/jpeg' || currentImageIdentificacion.type === 'image/png'">
            <v-img contain :lazy-src="previewImageIdentificacion" :src="previewImageIdentificacion" >
              <v-progress-linear
                v-if="loadingIne === true"
                indeterminate
                striped
                height="100%"
                value="0"
                color="green"
              ></v-progress-linear>
            </v-img>
          </div>
          <div v-else-if="currentImageIdentificacion.type === 'application/pdf'">
            <iframe :src="previewImageIdentificacion" style="height:320px;width:100%;"></iframe> 
          </div>
          <div v-else>OTRO Archivo</div>

          <v-alert v-if="alertWarningIne === true" icon="mdi-account" type="error">
            El archivo debe ser menos a 2MB
          </v-alert>

          <v-btn
            v-if="alertWarningIne === false"
            :disabled="loadedIne"
            class="mx-2 my-2 float-left"
            fab dark small
            color="yellow darken-4"
            @click="onUploadIne()"
          >
            <v-icon v-if="loadingIne === false" dark>mdi-upload</v-icon>
            <v-progress-circular
              v-if="loadingIne === true"
              icon text indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            :disabled="!loadedIne"
            class="mx-2 my-2 float-right"
            dark small
            color="red"
            @click="deleteImage(dataForm.documentoIdentificacion)"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
      </v-col>



      
<!--- <v-col col md="4" sm="6" xs="12" class="">
        <v-select
          :items="nationality"
          filled
          label="Nacionalidad:"
          prepend-icon="mdi-earth"
          v-model="dataForm.tipoNacionalidad"
        ></v-select>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-file-input
          show-size
          label="Documento que acredite la nacionalidad mexicana:"
          :rules="rules.uploadNacionalidad"
          @change="selectImageNacionalidad"
          v-model="currentImageNacionalidad"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>

        <div v-if="previewImageNacionalidad">
          <div v-if="currentImageNacionalidad.type === 'image/jpeg' || currentImageNacionalidad.type === 'image/png'">
            <v-img
              :lazy-src="previewImageNacionalidad"
              :src="previewImageNacionalidad"
              contain
            ></v-img>
          </div>
          <div v-else-if="currentImageNacionalidad.type === 'application/pdf'">
            <v-icon size="75">mdi-file-pdf-box</v-icon>
          </div>
          <div v-else>OTRO Archivo</div>
          <v-btn
              class="mx-2 my-2 float-left"
              fab
              dark
              small
              color="yellow darken-4"
              @click="onUpload"
            >
              <v-icon dark>mdi-upload</v-icon>
            </v-btn>
          <v-btn
            class="mx-2 my-2 float-right"
            fab
            dark
            small
            color="red"
            @click="clearNacionalidad()"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>


      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-file-input
          show-size
          label="Identificación adicional para identificar a la persona:"
          :rules="rules.uploadIdentificacion"
          @change="selectImageIdentificacion"
          v-model="currentImageIdentificacion"
          accept="image/jpeg,image/png,application/pdf"
        ></v-file-input>

        <div v-if="previewImageIdentificacion">
          <div v-if="currentImageIdentificacion.type === 'image/jpeg' || currentImageIdentificacion.type === 'image/png'">
            <v-img
              :src="previewImageIdentificacion"
              contain
            ></v-img>
          </div>
          <div v-else-if="currentImageIdentificacion.type === 'application/pdf'">
            <v-icon size="75">mdi-file-pdf-box</v-icon>
          </div>
          <div v-else>OTRO Archivo</div>
          <v-btn
              class="mx-2 my-2 float-left"
              fab
              dark
              small
              color="yellow darken-4"
              @click="onUpload"
            >
              <v-icon dark>mdi-upload</v-icon>
            </v-btn>
          <v-btn
            class="mx-2 my-2 float-right"
            fab
            dark
            small
            color="red"
            @click="clearIdentificacion()"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
      </v-col>-->


      <v-col col md="4" sm="6" xs="12" class=""><!--  v-if='dataForm.tipoNacionalidad === "nacimiento"' -->
        <v-autocomplete
          v-model="dataForm.stateBirth"
          :items="statesBirth"
          item-text="name"
          item-value="id"
          filled
          label="Estado/Provincia:"
          prepend-icon="mdi-earth"
          @change="loadTownsBirth"
          no-data-text="Selecciona tu nacionalidad"
          :disabled="dataForm.tipoNacionalidad !== 'nacimiento'"
        ></v-autocomplete>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class=""><!--  v-if='dataForm.tipoNacionalidad === "nacimiento"' -->
        <v-autocomplete
          v-model="dataForm.townshipBirth"
          :items="townsBirth"
          item-text="name"
          item-value="id"
          filled
          label="Condado/Distrito:"
          prepend-icon="mdi-earth"
          no-data-text="Selecciona un estado"
          :disabled="dataForm.tipoNacionalidad !== 'nacimiento'"
        ></v-autocomplete>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataForm.fechaNacimiento"
              label="Fecha de nacimiento"
              prepend-icon="mdi-calendar"
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataForm.fechaNacimiento"
            :active-picker.sync="activePicker"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
            locale="es-mx"
          ></v-date-picker>
        </v-menu>
      </v-col>


      <v-col col md="4" sm="6" xs="12" class="">
        <v-select
          :items="sexo"
          filled
          label="Sexo:"
          prepend-icon="mdi-human-male-female"
          v-model="dataForm.sexo"
        ></v-select>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="CURP: "
          prepend-icon="mdi-form-textbox"
          v-model="dataForm.curp"
          :rules="rules.curp"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
      </v-col>



      <v-col col md="12" class="">
        <h2>Domicilio:</h2>
      </v-col>

      <v-col col md="4" sm="6" xs="12" class="">
        <v-autocomplete
          v-model="dataForm.countryIntegrante"
          :items="countries"
          item-text="name"
          item-value="id"
          filled
          label="País:"
          prepend-icon="mdi-earth"
          @change="loadStates"
        ></v-autocomplete>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-autocomplete
          v-model="dataForm.stateIntegrante"
          :items="states"
          item-text="name"
          item-value="id"
          filled
          label="Estado/Provincia:"
          prepend-icon="mdi-earth"
          @change="loadTowns"
          no-data-text="Selecciona un país"
        ></v-autocomplete>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-autocomplete
          v-model="dataForm.townIntegrante"
          :items="towns"
          item-text="name"
          item-value="id"
          filled
          label="Condado/Distrito:"
          prepend-icon="mdi-earth"
          no-data-text="Selecciona un estado"
        ></v-autocomplete>
      </v-col>

      <!--<v-col col md="4" sm="6" xs="12" class="">
        <v-autocomplete
          v-model="dataForm.cityIntegrante"
          :items="cities"
          item-text="name"
          item-value="id"
          filled
          label="Ciudad:"
          prepend-icon="mdi-earth"
          no-data-text="Selecciona un estado"
        ></v-autocomplete>
      </v-col>-->
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Ciudad:"
          prepend-icon="mdi-earth"
          v-model="dataForm.ciudadIntegrante"
        ></v-text-field>
      </v-col>
      <v-col col md="8" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Calle:"
          prepend-icon="mdi-map-marker"
          v-model="dataForm.calleIntegrante"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Codigo Postal:"
          prepend-icon="mdi-numeric"
          v-model="dataForm.codigoPostalIntegrante"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Número exterior:"
          prepend-icon="mdi-numeric"
          v-model="dataForm.numeroExteriorIntegrante"
        ></v-text-field>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <v-text-field
          filled
          label="Número interior:"
          prepend-icon="mdi-numeric"
          v-model="dataForm.numeroInteriorIntegrante"
        ></v-text-field>
      </v-col>


        <v-col col md="12" class="">
          <h2>Datos de contacto:</h2>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Correo electrónico:"
            prepend-icon="mdi-at"
            v-model="dataForm.emailIntegrante"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
  
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono fijo:"
            prepend-icon="mdi-phone"
            v-model="dataForm.telefonoFijoIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono móvil:"
            prepend-icon="mdi-cellphone-basic"
            v-model="dataForm.telefonoMovilIntegrante"
          ></v-text-field>
        </v-col>


        <v-col col md="12" class="">
          <h2>Redes Sociales:</h2>
          <small class="">Indicar solo el Usuario</small>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Twitter:"
            prepend-icon="mdi-twitter"
            v-model="dataForm.twitterIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Facebook:"
            prepend-icon="mdi-facebook"
            v-model="dataForm.facebookIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Tiktok:"
            prepend-icon="mdi-alpha-t-circle-outline"
            v-model="dataForm.tiktokIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Instagram:"
            prepend-icon="mdi-instagram"
            v-model="dataForm.instagramIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Otra red social:"
            prepend-icon="mdi-account-switch"
            v-model="dataForm.otraRedIntegrante"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>


        <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
          <v-btn
            :disabled="!valid"
            x-large
            color="red darken-4"
            dark
            class="float-right"
            @click="infoPreview()"
          >
            Guardar Datos del Representante
            <v-icon
              right
              dark
            >
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
      
      </v-row>
    </v-form>




    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="dorado-obscuro"
            >
              <v-btn
                class="rojo-obscuro"
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase strong">Vista previa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  x-large
                  color="verde"
                  dark
                  @click="saveRepresentantive"
                >
                  Guardar
                  <v-icon
                    right
                    dark
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-title class="text-h5">
              {{selectedItem.nombreOrganizacion}}
            </v-card-title>

            <v-card-text>

              <div>
                <v-alert type="success" v-show="alertSuccess">
                  Registro Guardado
                </v-alert>
              </div>


              
        <v-simple-table class="table-preview">
          <template v-slot:default>
            <tbody>
              
              <tr class="blue-grey darken-4">
                <td colspan="3" class="text-uppercase text-center">
                  <h1 class="white--text">Datos del Representante</h1>
                </td>
              </tr>

              <tr>
                <td width="33%">
                  <small class="light overline">Cargo</small><br>
                  <strong class="text-capitalize">{{ reeplace_str(selectedItem.cargoIntegrante,'-',' ') }}</strong>
                </td>
                <td width="33%">
                  <small class="light overline">Fecha de Nacimiento</small><br>
                  <strong>{{ selectedItem.fechaNacimiento }}</strong>
                </td>
                <td width="33%">
                  <small class="light overline">CURP</small><br>
                  <strong>{{ selectedItem.curp }}</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Nombre(s)</small><br>
                  <strong>{{ selectedItem.nombreCompleto }}</strong>
                </td>
                <td>
                  <small class="light overline">Apellido Paterno</small><br>
                  <strong>{{ selectedItem.apellidoPaterno }}</strong>
                </td>
                <td>
                  <small class="light overline">Apellido Materno</small><br>
                  <strong>{{ selectedItem.apellidoMaterno }}</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Tipo de nacionalidad</small><br>
                  <strong class="text-capitalize">por {{ reeplace_str(selectedItem.tipoNacionalidad,'-',' ') }}</strong>
                </td>
                <td>
                  <small class="light overline">Estado de Nacimiento</small><br>
                  <strong>{{ selectedItem.stateBirth.name }}</strong>
                </td>
                <td>
                  <small class="light overline">Condado/Municipio de Nacimiento</small><br>
                  <strong>{{ selectedItem.townshipBirth }}</strong>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Domicilio</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Páis</small><br>
                  <strong>{{ selectedItem.countryIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">estado/Provincia</small><br>
                  <strong>{{ selectedItem.stateIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Condado/Distrito</small><br>
                  <strong>{{ !selectedItem.townIntegrante ? 'Sin Selección' : selectedItem.townIntegrante }}</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Ciudad</small><br>
                  <strong>{{ selectedItem.ciudadIntegrante }}</strong>
                </td>
                <td colspan="2">
                  <small class="light overline">Calle</small><br>
                  <strong>{{ selectedItem.calleIntegrante }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <small class="light overline">Código Postal</small><br>
                  <strong>{{ selectedItem.codigoPostalIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Número Exterior</small><br>
                  <strong>{{ selectedItem.numeroExteriorIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Número Interior</small><br>
                  <strong>{{ selectedItem.numeroInteriorIntegrante }}</strong>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Datos de contacto</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Correo Electrónico</small><br>
                  <strong>{{ selectedItem.emailIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Teléfono Fijo</small><br>
                  <strong>{{ selectedItem.telefonoFijoIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Teléfono Movil</small><br>
                  <strong>{{ selectedItem.telefonoMovilIntegrante }}</strong>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Redes Sociales</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Twitter</small><br>
                  <strong>{{ selectedItem.twitterIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Facebook</small><br>
                  <strong>{{ selectedItem.facebookIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Tiktok</small><br>
                  <strong>{{ selectedItem.tiktokIntegrante }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <small class="light overline">Instagram</small><br>
                  <strong>{{ selectedItem.instagramIntegrante }}</strong>
                </td>
                <td>
                  <small class="light overline">Otra red social</small><br>
                  <strong>{{ selectedItem.otraRedIntegrante }}</strong>
                </td>
                <td>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Documentos</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Documento que acredite la nacionalidad mexicana:</small><br>
                  <div v-if="selectedItem.documentoNacionalidad">
                    <div v-if="selectedItem.documentoNacionalidad.mime === 'application/pdf'">
                      <a :href="`${selectedItem.documentoNacionalidad.url}`" target="_blank" >
                        {{selectedItem.documentoNacionalidad.name}}
                      </a>
                    </div>
                    <div v-else>
                      <a :href="`${path.storage_files + selectedItem.documentoNacionalidad.url}`" target="_blank" class="orange">
                        <v-img
                          :lazy-src="path.storage_files + selectedItem.documentoNacionalidad.formats.thumbnail.url"
                          :src="path.storage_files + selectedItem.documentoNacionalidad.formats.thumbnail.url"
                          :alt="`${selectedItem.documentoNacionalidad.name}`"
                          :max-height="selectedItem.documentoNacionalidad.formats.thumbnail.height"
                          :max-width="selectedItem.documentoNacionalidad.formats.thumbnail.width"
                        ></v-img>
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <strong>Sin documento</strong>
                  </div>
                </td>
                <td>
                  <small class="light overline">Identificación adicional para identificar a la persona:</small><br>
                  <div v-if="selectedItem.documentoIdentificacion">
                    <div v-if="selectedItem.documentoIdentificacion.mime === 'application/pdf'">
                      <a :href="`${selectedItem.documentoIdentificacion.url}`" target="_blank" >
                        {{selectedItem.documentoIdentificacion.name}}
                      </a>
                    </div>
                    <div v-else>
                      <a :href="`${path.storage_files + selectedItem.documentoIdentificacion.url}`" target="_blank" class="orange">
                        <v-img
                          :lazy-src="path.storage_files + selectedItem.documentoIdentificacion.formats.thumbnail.url"
                          :src="path.storage_files + selectedItem.documentoIdentificacion.formats.thumbnail.url"
                          :alt="`${selectedItem.documentoIdentificacion.name}`"
                          :max-height="selectedItem.documentoIdentificacion.formats.thumbnail.height"
                          :max-width="selectedItem.documentoIdentificacion.formats.thumbnail.width"
                        ></v-img>
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <strong>Sin documento</strong>
                  </div>
                </td>
                <td>
                </td>
              </tr>
              
            </tbody>
          </template>
        </v-simple-table>
          


            </v-card-text>

            <v-card-actions class="dorado">
              <v-spacer></v-spacer>

              <v-btn
                x-large
                dark
                color="green"
                @click="dialog = false"
              >
                Continuar Modificando
                <v-icon
                  right
                  dark
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <pre>{{ dataForm }}</pre>


    
  </v-container>
</template>
<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import wsRepresentatives from "@/services/representatives.js";
import wsApplications from "@/services/application"
import wsAddress from "@/services/address.js"
import UserService from '@/services/user.service'


export default {
  name: 'RepresentativeDataComponent',

  data: () => ({
    valid: true,
    currentImageNacionalidad: undefined,
    previewImageNacionalidad: undefined,
    currentImageIdentificacion: undefined,
    previewImageIdentificacion: undefined,


    uploadFileNac: false,
    alertWarningNac: false,
    loadingNac: false,
    loadedNac: false,

    uploadFileIne: false,
    alertWarningIne: false,
    loadingIne: false,
    loadedIne: false,


    countries: [],
    states: [],
    towns: [],
    cities: [],
    statesBirth: [],
    townsBirth: [],
    dialog: false,
    alertSuccess: false,
    uuidOrganization: '',
    activePicker: null,
    date: null,
    menu: false,
    selectedItem: [],

    dataForm:{
      //application: null,
      //idSolicitud: '',
      //cargoIntegrante: '',
      //nombre: '',
      //apellidoPaterno: '',
      //apellidoMaterno: '',
      //stateBirth: '', //Relation
      //townshipBirth:'', //Relation
      //fechaNacimiento: '',
      //sexo: '',
      //curp: 'GABJ820624HDFRLN01',
      //tipoNacionalidad: '',
      //documentoNacionalidad: null,
      //documentoIdentificacion: null,
      //countryIntegrante: '', //Relation
      //stateIntegrante: '', //Relation
      //townIntegrante:'', //Relation
      //cityIntegrante:'', //Relation
      //codigoPostalIntegrante: '',
      //ciudadIntegrante: '',
      //numeroExteriorIntegrante: '',
      //numeroInteriorIntegrante: '',
      //emailIntegrante: 'jmarcosgb@gmail.com',
      //telefonoFijoIntegrante: '',
      //telefonoMovilIntegrante: '',
      //twitterIntegrante: '',
      //facebookIntegrante: '',
      //tiktokIntegrante: '',
      //instagramIntegrante: '',
      //otraRedIntegrante: '',
      //calleIntegrante:'',
      application: null,
      "cargoIntegrante": "presidente",
      "nombreCompleto": "Marcos",
      "apellidoPaterno": "Garcia",
      "apellidoMaterno": "Bello",
      "stateBirth": 1741,
      "townshipBirth": 279,
      "fechaNacimiento": "1982-06-24",
      "sexo": "hombre",
      "curp": "GABJ820624HDFRLN01",
      "tipoNacionalidad": "nacimiento",
      "documentoNacionalidad": null, //Imagen
      "documentoIdentificacion": null, //Imagen
      "countryIntegrante": 42,
      "stateIntegrante": 1747,
      "townIntegrante": 664,
      "cityIntegrante": 67117,
      "ciudadIntegrante": "CIUDAD",
      "codigoPostalIntegrante": "56335",
      "numeroExteriorIntegrante": "123",
      "numeroInteriorIntegrante": "999",
      "emailIntegrante": "jmarcosgb@gmail.com",
      "telefonoFijoIntegrante": "1234567890",
      "telefonoMovilIntegrante": "0987654321",
      "twitterIntegrante": "ime_sre",
      "facebookIntegrante": "ime_sre",
      "tiktokIntegrante": "ime_sre",
      "instagramIntegrante": "ime_sre",
      "otraRedIntegrante": "ime_sre",
      "calleIntegrante": "av Central"
    },

    jobs: [
      {
        value: '0',
        text: 'Selecciona...'
      },
      {
        value: 'presidente',
        text: 'Presidente'
      },
      {
        value: 'secretario',
        text: 'Secretario'
      },
      {
        value: 'tesorero',
        text: 'Tesorero'
      },
      {
        value: 'vocal',
        text: 'Vocal'
      },
      {
        value: 'miembro-mesa-directiva',
        text: 'Miembro de la Mesa Directiva'
      },
      {
        value: 'miembro',
        text: 'Miembro'
      },
    ],

    nationality: [
      {
        value: '0',
        text: 'Selecciona...'
      },
      {
        value: 'nacimiento',
        text: 'Por nacimiento'
      },
      {
        value: 'naturalizacion',
        text: 'Por naturalización'
      },
      {
        value: 'descendencia-mexicana',
        text: 'Por Descendencia Mexicana'
      },
    ],


    sexo: [
      {
        value: '0',
        text: 'Selecciona...'
      },
      {
        value: 'hombre',
        text: 'Hombre'
      },
      {
        value: 'mujer',
        text: 'Mujer'
      },
      {
        value: 'no-binario',
        text: 'No-binario'
      },
      {
        value: 'otro',
        text: 'Otro'
      },
    ],

    rules: {
      nombreOrganizacion: [
        value => !!value || 'Este campo es requerido',
      ],
      uploadNacionalidad: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
      uploadIdentificacion: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
      uploadFile: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
      email: [
        value => !!value || 'Este campo es requerido',
        value => /.+@.+/.test(value) || ' no es un correo valido',
      ],
      curp: [
        value => !!value || 'Este campo es requerido',
        value => /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(value.toUpperCase()) || ' no es una CURP valida',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),


  mounted(){
    if(!this.currentUser){
      this.$router.push('/auth/login')
    }

    this.appId = this.$localStorage.get("appID")

    this.loadStatesBirth(42);
    this.loadTownsBirth( this.dataForm.stateBirth ? this.dataForm.stateBirth : 0 );

    this.loadCountries();
    this.loadStates( this.dataForm.countryIntegrante ? this.dataForm.countryIntegrante : 0 );
    this.loadTowns( this.dataForm.stateIntegrante ? this.dataForm.stateIntegrante : 0 );
    this.loadCities( this.dataForm.stateIntegrante ? this.dataForm.stateIntegrante : 0 );

  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  methods:{

    saveRepresentantive(){
      
      var dataStorage = {
        application: JSON.parse(this.$localStorage.get("appID")),
        cargoIntegrante: this.dataForm.cargoIntegrante,
        nombreCompleto: this.dataForm.nombreCompleto,
        apellidoPaterno: this.dataForm.apellidoPaterno,
        apellidoMaterno: this.dataForm.apellidoMaterno,
        stateBirth: this.dataForm.stateBirth, //Relation
        townshipBirth: this.dataForm.townshipBirth, //Relation
        fechaNacimiento: this.dataForm.fechaNacimiento,
        sexo: this.dataForm.sexo,
        curp: this.dataForm.curp,
        tipoNacionalidad: this.dataForm.tipoNacionalidad,
        countryIntegrante: this.dataForm.countryIntegrante, //Relation
        stateIntegrante: this.dataForm.stateIntegrante, //Relation
        townIntegrante:this.dataForm.townIntegrante, //Relation
        cityIntegrante:this.dataForm.cityIntegrante, //Relation
        ciudadIntegrante: this.dataForm.ciudadIntegrante,
        codigoPostalIntegrante: this.dataForm.codigoPostalIntegrante,
        numeroExteriorIntegrante: this.dataForm.numeroExteriorIntegrante,
        numeroInteriorIntegrante: this.dataForm.numeroInteriorIntegrante,
        emailIntegrante: this.dataForm.emailIntegrante,
        telefonoFijoIntegrante: this.dataForm.telefonoFijoIntegrante,
        telefonoMovilIntegrante: this.dataForm.telefonoMovilIntegrante,
        twitterIntegrante: this.dataForm.twitterIntegrante,
        facebookIntegrante: this.dataForm.facebookIntegrante,
        tiktokIntegrante: this.dataForm.tiktokIntegrante,
        instagramIntegrante: this.dataForm.instagramIntegrante,
        otraRedOIntegrante: this.dataForm.otraRedIntegrante,
        calleIntegrante:this.dataForm.calleIntegrante,
        documentoNacionalidad: this.dataForm.documentoNacionalidad, //JSON.parse(this.$localStorage.get("imgNac")),
        documentoIdentificacion: this.dataForm.documentoIdentificacion //JSON.parse(this.$localStorage.get("imgIden"))
      }
      console.log("🚀 ~ file: representativeData.vue:792 ~ saveRepresentantive ~ dataStorage", dataStorage)
      
      wsRepresentatives.create({data:dataStorage}).then(
          response => {
            this.representative = response.data.data;
            this.loading = true
            this.updateApplication()
            //this.$router.push('/crom/board-directors/' + this.$route.params.uuid)
            this.$router.push('/dashboard/')
          },
          error => {
            this.representative =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )

    },


    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementValidation: true,
          organizationData: true,
          representativeData: true,
          boardDirectors: true
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },



    selectImageNacionalidad(image) {
      this.uploadFileNac = true;
      this.currentImageNacionalidad = image;
      this.previewImageNacionalidad = URL.createObjectURL(this.currentImageNacionalidad);
      if(this.$refs.formUploadNac.validate() === false){
        this.alertWarningNac = true
      }else{
        this.alertWarningNac = false
      }
    },

    selectImageIdentificacion(image) {
      this.uploadFileIne = true;
      this.currentImageIdentificacion = image;
      this.previewImageIdentificacion = URL.createObjectURL(this.currentImageIdentificacion);
      if(this.$refs.formUploadIne.validate() === false){
        this.alertWarningIne = true
      }else{
        this.alertWarningIne = false
      }
    },

    clearNacionalidad (){
      this.dataForm.documentoNacionalidad = {},
      this.currentImageNacionalidad = undefined;
      this.previewImageNacionalidad = undefined;
    },

    clearIdentificacion(){
      this.dataForm.documentoIdentificacion = {},
      this.currentImageIdentificacion = undefined;
      this.previewImageIdentificacion = undefined;
    },

    infoPreview() {
      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){
        this.dialog = true
        this.selectedItem = this.dataForm;
        this.uuidOrganization = this.$route.params.uuid;
      }
    },


    onUploadNac(){
      this.loadingNac = true
      const formData = new FormData()
      formData.append('files', this.currentImageNacionalidad)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.documentoNacionalidad = this.response_image.id
          this.$localStorage.set("imgNac", JSON.stringify(this.response_image))
          this.loadingNac = false
          this.loadedNac = true

        }).catch((error)=>{
          this.errorsUpload = error
        })
    },


    onUploadIne(){
      this.loadingIne = true
      const formData = new FormData()
      formData.append('files', this.currentImageIdentificacion)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.documentoIdentificacion = this.response_image.id
          this.$localStorage.set("imgIden", JSON.stringify(this.response_image))
          this.loadingIne = false
          this.loadedIne = true

        }).catch((error)=>{
          this.errorsUpload = error
        })
    },




    onUploadS(){
      const formData = new FormData()


      if(this.currentImageNacionalidad){
        formData.append('files', this.currentImageNacionalidad)

        axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.documentoNacionalidad = this.response_image.id
          this.$localStorage.set("imgNac", this.dataForm.documentoNacionalidad)
          this.clearNacionalidad();
        }).catch((error)=>{
          this.errorsUpload = error
        })

      }
      else if(this.currentImageIdentificacion){
        formData.append('files', this.currentImageIdentificacion)

        axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.documentoIdentificacion = this.response_image.id
          this.$localStorage.set("imgIden", this.dataForm.documentoIdentificacion)
          this.clearNacionalidad();
        }).catch((error)=>{
          this.errorsUpload = error
        })

      }

      console.log("🚀 ~ file: representativeData.vue:846 ~ onUpload ~ formData", formData)

    },




    deleteImage(id){
      wsOrganization.deleteFile(id).then(
        response => {
          this.removeImage = response.data.data;
          this.clearUpload()
          console.log("🚀 ~ file: organizationData.vue:978 ~ deleteImage ~ this.removeImage", this.removeImage)
        },
        error => {
          this.removeImage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log("🚀 ~ file: organizationData.vue:985 ~ deleteImage ~ this.removeImage", this.removeImage)
        }
      )
    },



    loadCountries() {
      wsAddress.getAllCountries()
        .then(response => {
          this.countries = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadStates(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.states = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTowns(id) {
      wsAddress.getTownByState(id)
        .then(response => {
          this.towns = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });

      wsAddress.getCityByState(id)
        .then(response => {
          this.cities = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadCities(id) {
      wsAddress.getCityByState(id)
        .then(response => {
          this.cities = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadStatesBirth(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.statesBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTownsBirth(id) {
      wsAddress.getTownByState(id)
        .then(response => {
          this.townsBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    save(date) {
      this.$refs.menu.save(date)
    },




    reeplace_str(string, rep, repWith) {
      const result = string.split(rep).join(repWith)
      return result; 
    },



  },


}
</script>