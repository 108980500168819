<template>
  <section class="oraganization-data">

    <span v-switch="user_role">
      <v-btn text to="/admin/dashboard" class="mr-2" v-if="user_role === 'SysAdmin'">SysAdmin</v-btn>

      <span v-else-if="user_role === 'ime_certificador'">{{ redirectToDashboard }}</span>
      <span v-else-if="user_role === 'ime_validador'">{{ redirectToDashboard }}</span>
      <span v-else-if="user_role === 'ime_observador'">{{ redirectToDashboard }}</span>
      <span v-else-if="user_role === 'rme_certificador'">{{ redirectToDashboard }}</span>
      <span v-else-if="user_role === 'rme_validador'">{{ redirectToDashboard }}</span>

      <CromOrganizationDataComponent v-else-if="user_role === 'organizacion'" ></CromOrganizationDataComponent>
      <span v-else-if="user_role === 'Authenticated'">{{ redirectToDashboard }}</span>
      <ProgressComponent v-else></ProgressComponent>
    </span>

	</section>
</template>

<script>
import UserService from '@/services/user.service'
import ProgressComponent from '@/components/global/_Progress.vue'
import CromOrganizationDataComponent from '@/components/roles/organizacion/crom/organizationData.vue'

export default {
  name: 'OrganizationDataView',
  
  components: {
    ProgressComponent,
    CromOrganizationDataComponent
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    redirectToDashboard(){
      return this.$router.push('/dashboard/');
    }
  },

  mounted(){
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }


  },

  methods:{
    logOut() {
      this.$localStorage.remove('appID')
      this.$localStorage.remove('img501C3')
      this.$localStorage.remove('imgIden')
      this.$localStorage.remove('imgNac')
      this.$localStorage.remove('orgId')
      this.$localStorage.remove('orgType')
      this.$localStorage.remove('userRole')
      this.$localStorage.remove('rmeData')

      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    }
  },

}
</script>