<template>
  <div class="preview board-directors-data">
    <h3>Mesa Directiva</h3>
    <pre>{{uuid}}</pre>
	</div>
</template>
<script>

export default {
  name: 'PreviewBoardDirectorsComponent',

  props: {
    uuid: String
  },
  
  components: {
    
  },

  data: () => ({

  }),

  mounted(){

  },

  computed: {

  },

  methods:{

  },

}
</script>