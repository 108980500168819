<template>
  <div class="preview representative-data">

    {{appId}}

	</div>
</template>
<script>
import wsApplications from '@/services/application'

export default {
  name: 'PreviewRepresentativeDataComponent',

  props: {
    appId: String
  },
  
  components: {
    
  },

  data: () => ({
    representative: [],
    
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  mounted(){
    this.loadRepresentative(this.appId)

  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  methods:{
    
    loadRepresentative(id){
      wsApplications.getRepresentativesFull(id).then(
        response => {
          this.representative = response.data.data[0];
          console.log("🚀 ~ file: representativeData.vue:271 ~ loadRepresentative ~ this.representative", this.representative)
        },
        error => {
          this.representative =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    reeplace_str(string, rep, repWith) {
      const result = string.split(rep).join(repWith)
      return result; 
    },

  },

}
</script>