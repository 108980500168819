import http from '@/services/http-common';

class wsAddress {

  getAllCountries() {
    return http.get('/countries/?fields[0]=id&fields[1]=name&pagination[pageSize]=215&sort=name:asc');
  }

  getStateByCountry(id) {
    return http.get(`/states/?fields[0]=id&fields[1]=name&filters[country][id][$eq]=${id}&pagination[pageSize]=500&sort=name:asc`);
  }

  getTownByState(id) {
    return http.get(`/townships/?fields[0]=id&fields[1]=name&filters[state][id][$eq]=${id}&pagination[pageSize]=500&sort=name:asc`);
  }

  getCityByState(id) {
    return http.get(`/towns/?fields[0]=id,name&filters[state][id][$eq]=${id}&pagination[pageSize]=500&sort=name:asc`);
  }


}

export default new wsAddress();