<template>
  <div class="preview co-investment-data">
    <h3>Coinversión</h3>
    <pre>{{uuid}}</pre>
	</div>
</template>
<script>

export default {
  name: 'PreviewCoInvestmentComponent',

  props: {
    uuid: String
  },
  
  components: {
    
  },

  data: () => ({

  }),

  mounted(){

  },

  computed: {

  },

  methods:{

  },

}
</script>