<template>
  <span class="rme-certificators-menu">
    <v-btn to="/dashboard" text class="mr-1">
      <v-icon>mdi-view-dashboard</v-icon> Dashboard (RC)
    </v-btn>
	</span>
</template>
<script>

export default {
  name: 'MenuRmeCertificatorComponent',
  
  components: {
  },

  data: () => ({

  }),

  mounted(){

  },

  computed: {

  },

  methods:{

  },

}
</script>