<template>
  <v-container>

    <v-row>
      <v-col>
        <h1 class="display-2">
          Datos  {{ organizationType === 'club' ? 'del Club' : 'de la Federación' }}
        </h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>        
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>
        <v-col col md="4" sm="6" xs="12">
          <v-text-field
            filled
            label="Fecha de Solicitud:"
            :value="date"
            disabled
            prepend-icon="mdi-calendar"
            v-model="dataForm.fechaSolicitud"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-select
            :items="formType"
            filled
            label="Tipo de Solicitud:"
            prepend-icon="mdi-playlist-check"
            v-model="dataForm.tipoSolicitud"
            :rules="rules.required"
          ></v-select>
        </v-col>


        <v-col col md="8" sm="6" xs="12" class="">
          <v-textarea
            filled
            label="Objetivo"
            rows="2"
            prepend-icon="mdi-text"
            v-model="dataForm.objetivo"
            :rules="rules.required"
          ></v-textarea>
        </v-col>
        <v-col v-if="organizationType === 'club'" col md="4" sm="6" xs="12" class="">
          <v-select
            v-model="dataForm.ambitoActividad"
            :items="activitiesClub"
            filled
            chips
            prepend-icon="mdi-format-list-checks"
            label="Ámbito de actividades:"
            multiple
            :rules="rules.required"
          ></v-select>
        </v-col>
        <v-col v-else col md="4" sm="6" xs="12" class="">
          <v-select
            v-model="dataForm.ambitoActividad"
            :items="activitiesFederation"
            filled
            chips
            prepend-icon="mdi-format-list-checks"
            label="Ámbito de actividades:"
            multiple
            :rules="rules.required"
          ></v-select>
        </v-col>


        <v-col col md="12" class="">
          <h2>Datos de contacto:</h2>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Correo electrónico:"
            prepend-icon="mdi-at"
            v-model="dataForm.emailOrganizacion"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
  
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono fijo:"
            prepend-icon="mdi-phone"
            v-model="dataForm.telefonoFijoOrganizacion"
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono móvil:"
            prepend-icon="mdi-cellphone-basic"
            v-model="dataForm.telefonoMovilOrganizacion"
          ></v-text-field>
        </v-col>


        <v-col col md="12" class="">
          <h2>Redes Sociales:</h2>
          <small class="">Indicar solo el Usuario</small>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Twitter:"
            prepend-icon="mdi-twitter"
            v-model="dataForm.twitterOrganizacion"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Facebook:"
            prepend-icon="mdi-facebook"
            v-model="dataForm.facebookOrganizacion"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Tiktok:"
            prepend-icon="mdi-alpha-t-circle-outline"
            v-model="dataForm.tiktokOrganizacion"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Instagram:"
            prepend-icon="mdi-instagram"
            v-model="dataForm.instagramOrganizacion"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Otra red social:"
            prepend-icon="mdi-account-switch"
            v-model="dataForm.otraRedOrganizacion"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>

        

        <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
          <v-btn
            :disabled="!valid"
            x-large
            color="red darken-4"
            dark
            class="float-right"
            @click="infoPreview()"
          >
            Guardar Organización
            <v-icon
              right
              dark
            >
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
        
      </v-row>
    </v-form>


    <v-row>
      <v-col>
        <pre>{{ dataForm }}</pre>
      </v-col>
      <v-col>
        <pre>{{ currentOrganization }}</pre>
      </v-col>
    </v-row>



    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="dorado-obscuro"
            >
              <v-btn
                class="rojo-obscuro"
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase strong">Vista previa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  x-large
                  color="verde"
                  dark
                  @click="updateOrganization()"
                >
                  Guardar
                  <v-icon
                    right
                    dark
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-title class="text-h5">
              {{selectedItem.nombreOrganizacion}}
            </v-card-title>

            <v-card-text>

              <div>
                <v-alert type="success" v-show="alertSuccess">
                  Registro Guardado
                </v-alert>
                <v-alert type="warning" v-show="alertError">
                  Registro Guardado
                </v-alert>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead class="blue-grey darken-2">
                    <tr>
                      <th scope="col" colspan="3" class="text-center white--text">
                        <h2>Datos de la Organización:</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <small>Fecha de Solicitud:</small><br>
                        <strong>{{selectedItem.fechaSolicitud}}</strong>
                      </td>
                      <td>
                        <small>Tipo de Solicitud:</small><br>
                        <strong>{{selectedItem.tipoSolicitud}}</strong>
                      </td>
                      <td>
                        <small> Ámbito de actividades:</small><br>
                        <ol class="strong">
                          <li v-for="(item,i) in selectedItem.ambitoActividad" :key="i">
                            {{item}}
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <small>Objetivo:</small><br>
                        <strong>{{selectedItem.objetivo}}</strong>
                      </td>
                    </tr>
                  </tbody>
                  <thead class="blue-grey darken-2">
                    <tr>
                      <th scope="col" colspan="3" class="text-center white--text">Datos de contacto de la Organización</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>
                      <small> Correo electrónico:</small><br>
                      <strong>{{selectedItem.emailOrganizacion}}</strong>
                    </td>
                    <td>
                      <small> Teléfono fijo: </small><br>
                      <strong>{{selectedItem.telefonoFijoOrganizacion}}</strong>
                    </td>
                    <td>
                      <small> Teléfono móvil: </small><br>
                      <strong>{{selectedItem.telefonoMovilOrganizacion}}</strong>
                    </td>
                  </tr>
                  <thead class="blue-grey darken-2">
                    <tr>
                      <th scope="col" colspan="3" class="text-center white--text">Redes Sociales</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>
                      <small>Twitter:</small><br>
                      @<strong>{{selectedItem.twitterOrganizacion}}</strong>
                    </td>
                    <td>
                      <small>Facebook: </small><br>
                      fb.me/<strong>{{selectedItem.facebookOrganizacion}}</strong>
                    </td>
                    <td>
                      <small>TikTok: </small><br>
                      @<strong>{{selectedItem.tiktokOrganizacion}}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Instagram:</small><br>
                      @<strong>{{selectedItem.instagramOrganizacion}}</strong>
                    </td>
                    <td>
                      <small>Otra Red: </small><br>
                      <strong>{{selectedItem.otraRedOrganizacion}}</strong>
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions class="dorado">
              <v-spacer></v-spacer>

              <v-btn
                x-large
                dark
                color="green"
                @click="dialog = false"
              >
                Continuar Modificando
                <v-icon right dark
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      
    </v-row>

  </v-container>
</template>
<script>
import wsOrganization  from "@/services/organizations"
import wsApplications from '@/services/application'
import jsonCatalogs from '@/services/_catalogos.json'
import ProgressComponent from '@/components/global/_Progress.vue'

export default {
  name: 'CromOrganizationDataComponent',
  
  components: {
    ProgressComponent
  },

  data: () => ({
    valid: true,
    organization: [],
    currentOrganization: [],
    organizationType: '',
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),

    catalogs: jsonCatalogs,
    
    dialog: false,
    alertSuccess: false,
    alertError: false,

    selectedItem: {
      ambitoActividad: [],
    },

    rules: {
      required: [
        value => !!value || 'Este campo es requerido',
      ],
      email: [
        value => !!value || 'Este campo es requerido',
        value => /.+@.+/.test(value) || 'Con es un correo valido',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

    dataForm:{
      fechaSolicitud: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
      tipoSolicitud: '',
      objetivo:'',
      ambitoActividad: [],
      emailOrganizacion: 'jmarcosgb@gmail.com',
      telefonoFijoOrganizacion: '',
      telefonoMovilOrganizacion: '',
      twitterOrganizacion: '',
      facebookOrganizacion: '',
      tiktokOrganizacion: '',
      instagramOrganizacion: '',
      otraRedOrganizacion:'',
      application: null,
    },


  }),

  mounted(){
    document.title = 'Instituto de los Mexicanos en el Exterior';
    this.organizationType = this.$localStorage.get("orgType")
    this.loadOrganization(this.routeUuid)
  },
    
    

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    formType() {
      return jsonCatalogs.formType.map((item) => {
        return item;
      })
    },

    activitiesFederation() {
      return jsonCatalogs.activitiesFederation.map((item) => {
        return item;
      })
    },

    activitiesClub() {
      return jsonCatalogs.activitiesClub.map((item) => {
        return item;
      })
    },

    testList() {
      return jsonCatalogs.testList.map((item) => {
        return item;
      })
    },
    
    routeUuid(){
      return this.$route.params.uuid;
    },

    organizationId(){
      return this.currentOrganization.id
    }

  },

  methods:{

    infoPreview() {
      this.$refs.form.validate();
      console.log('VALIDATE =>'+ this.$refs.form.validate())
      if(this.$refs.form.validate() === true){
        this.dialog = true
        this.selectedItem = this.dataForm;
      }
    },


    loadOrganization(uuid){
      wsApplications.findByUuidAndOrganization(uuid).then(
        response => {
          this.currentOrganization = response.data.data.organization
          this.$localStorage.set("userOrganization", JSON.stringify(this.currentOrganization))
        },
        error => {
          this.currentOrganization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },



    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },

    updateOrganization(){
      var data = {
        fechaSolicitud: this.dataForm.fechaSolicitud,
        objetivo: this.dataForm.objetivo,
        emailOrganizacion: this.dataForm.emailOrganizacion,
        telefonoFijoOrganizacion: this.dataForm.telefonoFijoOrganizacion,
        telefonoMovilOrganizacion: this.dataForm.telefonoMovilOrganizacion,
        twitterOrganizacion: this.dataForm.twitterOrganizacion,
        facebookOrganizacion: this.dataForm.facebookOrganizacion,
        tiktokOrganizacion: this.dataForm.tiktokOrganizacion,
        instagramOrganizacion: this.dataForm.instagramOrganizacion,
        otraRedOrganizacion: this.dataForm.otraRedOrganizacion,
        tipoSolicitud: this.dataForm.tipoSolicitud,
        ambitoActividad: this.dataForm.ambitoActividad
      }
      wsOrganization.update(this.currentOrganization.id, {data:data}).then(
        response => {
          this.organization = response.data.data;
          this.loading = true
          this.updateApplication()
          //this.$router.push('/crom/representative-data/' + this.$route.params.uuid)
          this.$router.push('/dashboard/')
        },
        error => {
          this.organization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },



    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementValidation: true,
          organizationData: true,
          representativeData: true
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


    
  },

}
</script>
<style scoped>
  .bottom-gradient {
    background-image: linear-gradient(to top,rgba(0, 0, 0, 0.4) 0%, transparent 72px);
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
                        rgba(255,0,0,.25),
                        rgba(255,0,0,.25) 5px,
                        rgba(0,0,255,.25) 5px,
                        rgba(0,0,255,.25) 10px
                      );
  }
</style>