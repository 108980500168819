<template>
  <div class="text-center">
		<v-progress-circular
			v-default
			:size="75"
			:width="8"
			:color="cfgColor"
			indeterminate
		></v-progress-circular>
  </div>
</template>
<script>

export default {
  name: 'ProgressComponent',
	props: {
		cfgColor: {
			type: String,
			default: 'red darken-2'
		}
	}
}
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>