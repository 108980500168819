<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Mesa Directiva</h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>
        <v-col col md="6" sm="6" xs="12">
          <v-text-field
            :disabled="members.length >= 5"
            filled
            label="Nombre Completo:"
            prepend-icon="mdi-account"
            v-model="dataForm.nombreCompleto"
            :rules="rules.nombreCompleto"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12">
          <v-select
            :disabled="members.length >= 5"
            :items="jobs"
            filled
            label="Cargo:"
            prepend-icon="mdi-account-hard-hat"
            v-model="dataForm.cargoIntegrante"
            :rules="rules.cargoIntegrante"
          ></v-select>
        </v-col>

        <v-col col md="2" sm="6" xs="12" class="">
          <v-btn
            fab
            color="green darken-2"
            dark
            @click="addMember"
            :disabled="!valid || members.length >= 5"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>


    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead class="blue-grey darken-4">
              <tr class="">
                <th class="white--text">#</th>
                <th class="text-uppercase white--text">
                  Nombre completo
                </th>
                <th class="text-uppercase white--text">
                  Cargo
                </th>
                <th class="text-uppercase white--text">
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in members"
                :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td class="strong">{{ item.nombreCompleto }}</td>
                <td>{{ item.cargoIntegrante }}</td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    color="pink"
                  >
                    <v-icon dark @click="deleteMember(item.id)">
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>


    <v-row>
      <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
        <v-btn
          :disabled="members.length < 5"
          x-large
          color="red darken-4"
          dark
          class="float-right"
          @click="updateApplication()"
        >
          Guardar miembros de la mesa directiva
          <v-icon
            right
            dark
          >
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>




    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="dorado-obscuro"
            >
              <v-btn
                class="rojo-obscuro"
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase strong">Vista previa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>

                <v-btn
                  x-large
                  color="verde"
                  dark
                  @click="updateApplication()"
                >
                  Guardar
                  <v-icon
                    right
                    dark
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>

              </v-toolbar-items>
            </v-toolbar>

            <v-card-title class="text-h5">
              {{selectedItem.nombreOrganizacion}}
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col" class="text-center">
                        <h2>Mesa Directiva:</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td >
                        <pre>{{selectedItem}}</pre>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions class="dorado">
              <v-spacer></v-spacer>

              <v-btn
                x-large
                dark
                color="green"
                @click="dialog = false"
              >
                Continuar Modificando
                <v-icon
                  right
                  dark
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    
    <div>
      <v-alert type="success" v-show="alertSuccess">
        Registro Guardado
      </v-alert>
      <v-alert type="error" v-show="alertError">
        Error al  Guardar
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :multi-line="multiLine"
        absolute
        centered
        elevation="20"
        color="light-blue"
      >
        <h3 class="light-blue--text text--lighten-5">Registro Guardado</h3>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue-grey darken-4"
            fab
            v-bind="attrs"
            @click="snackbar = false"
            small
          >
            <v-icon light>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackbarDelete"
        :timeout="timeout"
        :multi-line="multiLine"
        absolute
        centered
        elevation="20"
        color="orange darken-4"
      >
        <h3 class="white--text">Miembro Eliminado</h3>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white orange--text text--darken-4"
            fab
            v-bind="attrs"
            @click="snackbarDelete = false"
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>


    </div>

  </v-container>
</template>
<script>
import wsMembers from "@/services/members.js";
import wsApplications from "@/services/application"
import wsAddress from "@/services/address.js"

import UserService from '@/services/user.service'

export default {
  name: 'BoardDirectorsComponent',

  data: () => ({
    valid: true,
    alertSuccess: false,
    alertError: false,
    dialog: false,
    multiLine: true,
    uuidOrganization: '',
    //uuidOrganization: this.$route.params.uuid,
    selectedItem: [],
    members: [],

    snackbar: false,
    snackbarDelete: false,
    timeout: 5000,

    dataForm:{
      application: null,
      idSolicitud: '',
      nombreCompleto: '',
      cargoIntegrante: '',
      esMesaDirectiva: true,
    },
    jobs: [
      {
        value: 'presidente',
        text: 'Presidente'
      },
      {
        value: 'secretario',
        text: 'Secretario'
      },
      {
        value: 'tesorero',
        text: 'Tesorero'
      },
      {
        value: 'vocal',
        text: 'Vocal'
      },
      {
        value: 'miembro-mesa-directiva',
        text: 'Miembro de la Mesa Directiva'
      },
    ],

    rules: {
      nombreCompleto: [
        value => !!value || 'Este campo es requerido',
      ],
      cargoIntegrante: [
        value => !!value || 'Este campo es requerido',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },



  }),


  mounted(){
    if(!this.currentUser){
      this.$router.push('/auth/login')
    }

    this.uuidOrganization = this.$route.params.uuid
    this.loadMembers(this.uuidOrganization);

  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },


  methods:{
    
    addMember(){

      var dataStorage = {
        //data: {
          application: this.$localStorage.get("appID"),
          nombreCompleto: this.dataForm.nombreCompleto,
          cargoIntegrante: this.dataForm.cargoIntegrante,
          esMesaDirectiva: true
        //}
      }

      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){
        
        wsMembers.create({data:dataStorage}).then(
          response => {
            this.representative = response.data.data;

            this.snackbar = true;
            this.$refs.form.reset();
            this.loadMembers(this.$route.params.uuid);

            //this.loading = true
            //this.updateApplication()
            //this.$router.push('/crom/board-directors/' + this.$route.params.uuid)
          },
          error => {
            this.representative =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              console.log("🚀 ~ file: boardDirectors.vue:433 ~ addMember ~ this.representative", this.representative)
          }
        )


        
      }

    },

    getMember(id) {
      wsMembers.get(id)
        .then(response => {
          this.currentMember = response.data.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    removeMember(id){
      console.log("🚀 ~ file: BoardDirectors.vue ~ line 400 ~ removeMember ~ id", id)
      this.snackbarDelete = true
    },

    deleteMember(id) {
      wsMembers.delete(id)
        .then(response => {
          console.log("🚀 ~ file: BoardDirectors.vue ~ line 405 ~ deleteMember ~ response", response.data.data)
          //console.log(response.data);
          //this.$router.push({ name: "MemberRelationship" });
          this.loadMembers(this.uuidOrganization);
          this.snackbarDelete = true
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadMembers(uuid) {
      wsMembers.getDirectorsByOrganization(uuid)
        .then(response => {
          this.members = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },



    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementValidation: true,
          organizationData: true,
          representativeData: true,
          boardDirectors: true,
          memberRelationship: true
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
          //this.$router.push('/crom/member-relationship/' + this.$route.params.uuid)
          this.$router.push('/dashboard/')
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


    infoPreview() {
      //this.$refs.form.validate();
      //if(this.$refs.form.validate() === true){
        this.dialog = true
        this.selectedItem = this.dataForm;
        this.uuidOrganization = this.$route.params.uuid;
      //}
    },

  }


}
</script>