<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">
          <span>Carta de Validación</span>
        </h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>

        <v-col col md="8" sm="6" xs="12" class="">
          <div class="">
            <v-file-input
              label="Carta Aval con Firma Autografa"
              :rules="rules.upload"
              @change="selectImage"
              v-model="document"
              filled
              show-size
              accept="application/pdf"
              ref="formUpload"
            ></v-file-input>
          </div>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <div v-if="previewImage">
            <div v-if="currentImage.type === 'image/jpeg' || currentImage.type === 'image/png'">
              <v-img
                :lazy-src="previewImage"
                :src="previewImage"
                contain
              >
                <v-progress-linear
                  v-if="loading === true"
                  indeterminate
                  striped
                  height="100%"
                  value="0"
                  color="green"
                ></v-progress-linear>
              </v-img>
            </div>
            <div v-else-if="currentImage.type === 'application/pdf'">
              <iframe :src="previewImage" style="height:320px;width:100%;"></iframe> 
            </div>
            <div v-else>OTRO Archivo</div>

            <v-alert
              v-if="alertWarning === true"
              icon="mdi-account"
              type="error"
            >
              El archivo debe ser menos a 2MB
            </v-alert>

            <v-btn
              v-if="alertWarning === false"
              :disabled="loaded"
              class="mx-2 my-2 float-left"
              fab
              dark
              small
              color="yellow darken-4"
              @click="onUpload"
            >
              <v-icon v-if="loading === false" dark>mdi-upload</v-icon>
              <v-progress-circular
                v-if="loading === true"
                icon
                text
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
            <v-btn
              :disabled="!loaded"
              class="mx-2 my-2 float-right"
              
              dark
              small
              color="red"
              @click="deleteImage(dataForm.document)"
            >
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </div>
        </v-col>

      </v-row>


    </v-form>


    <v-row>
      <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">

        <v-btn
          :disabled="!valid"
          x-large
          color="red darken-4"
          dark
          class="float-right ma-1"
          @click="saveDocument()"
        >
          Enviar Carta Aval
          <v-icon
            right
            dark
          >
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ProgressComponent v-if="loading === true" />
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import ProgressComponent from '@/components/global/_Progress.vue'
import wsAddress from "@/services/address"
import wsOrganization  from "@/services/organizations"
import wsApplications from '@/services/application'
import wsExternal from '@/services/external'

export default {
  name: 'CromRmeDataComponent',
  
  components: {
    ProgressComponent
  },

  data: () => ({
    valid: true,
    loading: false,

    currentImage: undefined,
    previewImage: undefined,
    uploadFile: false,
    alertWarning: false,
    
    loading: false,
    loaded: false,
    
    document: [],

    dataForm: {
      document: null
    },

    rules: {
      upload: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){    

  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  methods:{

    updateApplicationTwo(){
      if(this.$refs.form.validate() === true){
        var data = {
          type_rme: this.application.type_rme,
          e_rme: this.application.e_rme,
          organizationType: this.application.organizationType,
          status: {
            rmeData: true,
            endorsement: true,
            endorsementUpload: true
          }
        }
        wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
          response => {
            this.application = response.data.data;
            this.loading = true
            //this.seedOrganization();
            this.$localStorage.set("orgType", this.application.organizationType)
            this.$router.push('/crom/organization-data/' + this.$route.params.uuid)
          },
          error => {
            this.application =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }
    },


    selectImage(image) {
      this.uploadFile = true;
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      console.log("🚀 ~ file: organizationData.vue:890 ~ selectImage ~ this.$refs.formUpload.validate()", this.$refs.formUpload.validate())
      if(this.$refs.formUpload.validate() === false){
        this.alertWarning = true
      }else{
        this.alertWarning = false
      }


    },

    clearUpload (){
      this.uploadFile = false;
      this.dataForm.document = null,
      this.document = '',
      this.currentImage = undefined;
      this.previewImage = undefined;
      this.$refs.form.reset()
    },


    loadDocument(id){
      wsOrganization.getImage(id)
        .then(response => {
          this.document = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },



    onUpload(){
      this.loading = true
      const formData = new FormData()
      formData.append('files', this.currentImage)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.document = this.response_image.id
          //this.$localStorage.set("img501C3", JSON.stringify(this.response_image))
          this.loading = false
          this.loaded = true

        }).catch((error)=>{
          this.errorsUpload = error
        })
    },


    deleteImage(id){
      wsOrganization.deleteFile(id).then(
        response => {
          this.removeImage = response.data.data;
          this.clearUpload()
        },
        error => {
          this.removeImage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },



    saveDocument(){
      var dataStorage = {
        application: JSON.parse(this.$localStorage.get("appID")),
        isValid: false,
        document: this.dataForm.document,
      }
      wsApplications.createEndorsement({data:dataStorage}).then(
        response => {
          this.document = response.data.data;
          this.loading = true
          this.updateApplication()
          //this.$router.push('/dashboard/' + this.$route.params.uuid)
        },
        error => {
          this.document =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementUpload: true
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
          this.$router.push('/dashboard/')
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


  },

}
</script>