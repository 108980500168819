<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="display-1">Firma de Certificado (RME)</h2>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">

          <v-file-input
            show-size
            label="Certificado (cer):"
            :rules="rules.required"
            accept=".cer"
            v-model="fiel.file_cer"
          ></v-file-input>
  
          <v-file-input
            show-size
            label="Clave privada (key):"
            :rules="rules.required"
            accept=".key"
            v-model="fiel.file_key"
          ></v-file-input>

          <v-text-field
            filled
            v-model="fiel.password"
            :rules="rules.password"
            prepend-icon="mdi-form-textbox-password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            label="Contraseña de clave privada:"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          ></v-text-field>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <pre>{{cadena_original}}</pre>
        </v-col>
      </v-row>

    </v-form>

</v-container>
</template>
<script>

export default {
  name: 'SignCertificatorComponent',
  
  components: {
  },

  data: () => ({
    valid: true,
    showpass: false,
    cadena_original: '||Invocante:IME|MXORG009|registro nuevo|club|Dragoncitos Migrantes|Estados Unidos|Consulmex Boise|Jacob Morales Juarez||',
    //https://my.test.platform.com/ocsp/handshake

    fiel: {
      file_cer: {},
      file_key: {},
      password: ''
    },

    rules: {
      required: [
        value => !!value || 'Este campo es requerido'
      ],
      uploadNacionalidad: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!'
      ],
      password: [
        v => !!v || 'La Contraseña es obligatoria',
      ]
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){
    console.log('FIRMA')
  },

  computed: {

  },

  methods:{

  },

}
</script>