<template>
  <section class="roles-menu">

    <v-btn to="/" text class="mr-1">
      <v-icon left>mdi-home</v-icon>
      Inicio
    </v-btn>

    <span v-switch="user_role">
      <v-btn text to="/admin/dashboard" class="mr-2" v-case="'SysAdmin'">SysAdmin</v-btn>
      <MenuImeCertificatorComponent v-case="'ime_certificador'" />
      <v-btn text to="/ime/dashboard" class="mr-2" v-case="'ime_validador'">ime_validador</v-btn>
      <v-btn text to="/ime/dashboard" class="mr-2" v-case="'ime_observador'">ime_observador</v-btn>
      <MenuRmeCertificatorComponent v-case="'rme_certificador'" />
      <MenuRmeValidatorComponent v-case="'rme_validador'" />
      <MenuOrganizationComponent v-case="'organizacion'" />
      <MenuAuthenticatedComponent v-case="'Authenticated'" />
      <v-btn text class="mr-2" v-default @click.prevent="logOut">Inicio</v-btn>
    </span>


    <v-btn to="/user/profile" text class="mr-1">
      <v-icon left>mdi-account-circle</v-icon>
      {{ currentUser.user.username }} 
      <span v-if="rme_data.name" class="light"> ({{rme_data.name}})</span>
    </v-btn>
    
    <v-btn 
      color="light-green darken-1" 
      @click.prevent="logOut"
      x-small
      fab
      class="mr-2"
    >
      <v-icon>mdi-account-arrow-right-outline</v-icon>
    </v-btn>

    <v-btn
      color="light-green darken-1"
      href="/help"
      fab
      x-small
      class="mr-2"
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>

	</section>
</template>
<script>
import UserService from '@/services/user.service';
import MenuOrganizationComponent from '@/components/roles/organizacion/Menu.vue'
import MenuRmeValidatorComponent from '@/components/roles/rme_validador/Menu.vue'
import MenuRmeCertificatorComponent from '@/components/roles/rme_certificador/Menu.vue'
import MenuImeCertificatorComponent from '@/components/roles/ime_certificador/Menu.vue'
import MenuAuthenticatedComponent from '@/components/roles/Authenticated/Menu.vue'

export default {
  name: 'MenuRolesComponent',
  
  components: {
    MenuOrganizationComponent,
    MenuRmeValidatorComponent,
    MenuRmeCertificatorComponent,
    MenuImeCertificatorComponent,
    MenuAuthenticatedComponent,
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted(){
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }


  },

  methods:{
    logOut() {
      this.$localStorage.remove('appID')
      this.$localStorage.remove('img501C3')
      this.$localStorage.remove('imgIden')
      this.$localStorage.remove('imgNac')
      this.$localStorage.remove('orgId')
      this.$localStorage.remove('orgType')
      this.$localStorage.remove('userRole')
      this.$localStorage.remove('rmeData')
      this.$localStorage.remove('userProfile')
      this.$localStorage.remove('userRme')

      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    }
  },

}
</script>