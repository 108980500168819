<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">
          <span v-if="application.organizationType">Carta Aval</span>
          <span v-else>Tipo de Organización</span>
        </h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="application.type_rme"
            :value="application.type_rme"
            :items="type_rme"
            item-text="name"
            item-value="id"
            filled
            label="Tipo de Representación:"
            prepend-icon="mdi-earth"
            @change="loadRme"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>  
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="application.e_rme"
            :items="representation"
            item-text="name"
            item-value="id"
            filled
            label="Representación:"
            prepend-icon="mdi-earth-arrow-right"
            no-data-text="Selecciona un tipo de Representación"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12">
          <v-select
            :items="organizationType"
            filled
            label="Tipo de Organización:"
            prepend-icon="mdi-playlist-check"
            v-model="application.organizationType"
            :rules="rules.required"
          ></v-select>
        </v-col>
      </v-row>


      <v-row>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Nombre de la Organización"
            prepend-icon="mdi-sitemap"
            v-model="dataForm.nombreOrganizacion"
            :rules="rules.required"
            value="Dragones Migrantes"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Miembros:"
            prepend-icon="mdi-account-multiple"
            v-model="dataForm.numeroMiembros"
            :rules="application.organizationType === 'club' ? rules.clubCount : rules.federationCount"
          ></v-text-field>
        </v-col>
        <v-col v-if="application.organizationType === 'federacion'" col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Clubes:"
            prepend-icon="mdi-account-group"
            v-model="dataForm.numeroClubes"
            :rules="rules.required"
          ></v-text-field>
        </v-col>

      </v-row>


      <v-row>

        <v-col v-if="application.organizationType === 'club'" md="4" sm="6" xs="12" class="">
          ¿Cuenta con comprobante de estatus de 501?
          <v-radio-group v-model="dataForm.estatus501C3">
            <v-radio label="Si" value="true"></v-radio>
            <v-radio label="No" value="false"></v-radio>
          </v-radio-group>
        </v-col>


        <v-col v-if="dataForm.estatus501C3 === 'false'" md="4" sm="6" xs="12">
          <v-autocomplete
            v-model="dataForm.federation"
            :items="federations"
            item-text="name"
            item-value="id"
            filled
            label="Federación a la que perteneces"
            prepend-icon="mdi-earth-arrow-right"
            no-data-text="Selecciona una Federación"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>

        
        <v-col
          v-if="application.organizationType === 'federation'"
          col md="4" sm="6" xs="12" class="">
          <div class="">
            <v-file-input
              label="Comprobante de estatus de 501"
              :rules="rules.upload501C3"
              @change="selectImage"
              v-model="comprobantesEstatus501C3"
              filled
              show-size
              accept="image/jpeg,image/png,application/pdf"
              ref="formUpload"
            ></v-file-input>
          </div>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <div v-if="previewImage">
            <div v-if="currentImage.type === 'image/jpeg' || currentImage.type === 'image/png'">
              <v-img
                :lazy-src="previewImage"
                :src="previewImage"
                contain
              >
                <v-progress-linear
                  v-if="loading === true"
                  indeterminate
                  striped
                  height="100%"
                  value="0"
                  color="green"
                ></v-progress-linear>
              </v-img>
            </div>
            <div v-else-if="currentImage.type === 'application/pdf'">
              <iframe :src="previewImage" style="height:320px;width:100%;"></iframe> 
            </div>
            <div v-else>OTRO Archivo</div>

            <v-alert
              v-if="alertWarning === true"
              icon="mdi-account"
              type="error"
            >
              El archivo debe ser menos a 2MB
            </v-alert>

            <v-btn
              v-if="alertWarning === false"
              :disabled="loaded"
              class="mx-2 my-2 float-left"
              fab
              dark
              small
              color="yellow darken-4"
              @click="onUpload"
            >
              <v-icon v-if="loading === false" dark>mdi-upload</v-icon>
              <v-progress-circular
                v-if="loading === true"
                icon
                text
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
            <v-btn
              :disabled="!loaded"
              class="mx-2 my-2 float-right"
              
              dark
              small
              color="red"
              @click="deleteImage(dataForm.comprobantesEstatus501C3)"
            >
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </div>
        </v-col>

      </v-row>


      <v-row>

        
        <v-col col md="12" class="">
          <h2>Domicilio en el Exterior:</h2>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.countryExterior"
            :items="countries"
            item-text="name"
            item-value="id"
            filled
            label="País:"
            prepend-icon="mdi-earth"
            @change="loadStates"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.stateExterior"
            :items="states"
            item-text="name"
            item-value="id"
            filled
            label="Estado/Provincia:"
            prepend-icon="mdi-earth"
            @change="loadTowns"
            no-data-text="Selecciona un país"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.townshipExterior"
            :items="towns"
            item-text="name"
            item-value="id"
            filled
            label="Condado/Distrito:"
            prepend-icon="mdi-earth"
            no-data-text="Selecciona un estado"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Ciudad:"
            prepend-icon="mdi-earth"
            v-model="dataForm.ciudadOrganizacion"
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Codigo Postal:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.codigoPostal"
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Calle:"
            prepend-icon="mdi-map-marker"
            v-model="dataForm.calle"
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Número exterior:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.numeroExterior"
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Número interior:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.numeroInterior"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>

      </v-row>


      <v-row v-if="application.organizationType === 'club'">
        <v-col>
          <p class="lead">Requiere aval de federacion</p>
        </v-col>
      </v-row>

      <v-row v-if="application.organizationType === 'federacion'">
        <v-col>
          <p class="lead">Requiere comprobante 501 y no participa en coinversion</p>
        </v-col>
      </v-row>

      <v-row v-if="application.organizationType === 'asociacion'">
        <v-col>
          <p class="lead">Requiere comprobante 501 y puede participar en coinversion</p>
        </v-col>
      </v-row>


    </v-form>


    <v-row>
      <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">

        <v-btn
          :disabled="!valid"
          x-large
          color="red darken-4"
          dark
          class="float-right ma-1"
          href="/documents/CartaAvalClub.pdf"
          target="_blank"
        >
          Guardar Formato
          <v-icon
            right
            dark
          >
            mdi-file-pdf-box
          </v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          x-large
          color="red darken-4"
          dark
          class="float-right ma-1"
          @click="updateApplication()"
        >
          Guardar Datos
          <v-icon
            right
            dark
          >
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ProgressComponent v-if="loading === true" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <pre>{{ dataForm.numeroMiembros }}</pre>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import ProgressComponent from '@/components/global/_Progress.vue'
import wsAddress from "@/services/address"
import wsOrganization  from "@/services/organizations"
import wsApplications from '@/services/application'
import wsExternal from '@/services/external'

export default {
  name: 'CromRmeDataComponent',
  
  components: {
    ProgressComponent
  },

  data: () => ({
    valid: true,
    representation: [],
    type_rme: [],
    application: [],
    loading: false,
    federations: [],

    currentImage: undefined,
    previewImage: undefined,
    uploadFile: false,
    alertWarning: false,
    
    loading: false,
    loaded: false,
    
    comprobantesEstatus501C3: [],
    imageEstatus501C3: [],

    currentCountry: '',
    countries: [],
    states: [],
    towns: [],
    cities: [],
    
    dataForm:{
      nombreOrganizacion: '',
      numeroMiembros: '',
    },

    organizationType: [
      {
        value: 'club',
        text: 'Club',
      },
      {
        value: 'federacion',
        text: 'Federación'
      },
      {
        value: 'asociacion',
        text: 'Asociación'
      }
    ],

    rules: {
      required: [
        value => !!value || 'Este campo es requerido',
      ],
      upload501C3: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
      email: [
        value => !!value || 'Este campo es requerido',
        value => /.+@.+/.test(value) || 'Con es un correo valido',
      ],
      clubCount: [
        value => !!value || 'Este campo es requerido',
        value => !!value && value >= 10 || 'Deben ser al menos 10 integrantes'
      ],
      federationCount: [
        value => !!value || 'Este campo es requerido',
        value => !!value && value >= 20 || 'Deben ser al menos 20 integrantes'
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){

    this.loadCountries();
    this.loadStates( this.dataForm.countryExterior ? this.dataForm.countryExterior : 0 );
    this.loadTowns( this.dataForm.stateExterior ? this.dataForm.stateExterior : 0 );
    this.loadCities( this.dataForm.stateExterior ? this.dataForm.stateExterior : 0 );
    this.loadFederations();

    this.loadTypeRme()
    this.loadRme(this.application.type_rme)
    console.log("🚀 ~ file: rmeData.vue ~ line 137 ~ mounted ~ this.application.type_rme", this.application.type_rme)
    this.loadApplication(this.$localStorage.get("appID"))
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  methods:{

    loadTypeRme(){
      wsExternal.loadTypeRepresentations().then(
        response => {
          this.type_rme = response.data;
        },
        error => {
          this.type_rme =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadRme(rmeTypeId){
      wsExternal.loadRepresentationsbyRmeType(rmeTypeId).then(
        response => {
          this.representation = response.data;
        },
        error => {
          this.representation =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadApplication(id){
      wsApplications.get(id).then(
        response => {
          this.application = response.data.data;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },


    loadCountries() {
      wsAddress.getAllCountries()
        .then(response => {
          this.countries = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadStates(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.states = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTowns(id) { 
      wsAddress.getTownByState(id)
        .then(response => {
          this.towns = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadCities(id) {
      wsAddress.getCityByState(id)
        .then(response => {
          this.cities = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadFederations() {
      wsApplications.getAllFederations()
        .then(response => {
          this.federations = response.data.data;
          console.log("🚀 ~ file: organizationData.vue:912 ~ loadFederations ~ this.federations", this.federations)
        })
        .catch(e => {
          console.log(e);
        });
    },




    updateApplication(){
      if(this.$refs.form.validate() === true){
        var data = {
          type_rme: this.application.type_rme,
          e_rme: this.application.e_rme,
          organizationType: this.application.organizationType,
          status: {
            endorsement: true,
            rmeData: true,
            organizationData: true
          }
        }
        wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
          response => {
            this.application = response.data.data;
            this.loading = true
            //this.seedOrganization();
            this.$localStorage.set("orgType", this.application.organizationType)
            this.$router.push('/crom/organization-data/' + this.$route.params.uuid)
          },
          error => {
            this.application =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }
    },


    selectImage(image) {
      this.uploadFile = true;
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      console.log("🚀 ~ file: organizationData.vue:890 ~ selectImage ~ this.$refs.formUpload.validate()", this.$refs.formUpload.validate())
      if(this.$refs.formUpload.validate() === false){
        this.alertWarning = true
      }else{
        this.alertWarning = false
      }


    },

    clearUpload (){
      this.uploadFile = false;
      this.dataForm.estatus501C3 = "false",
      this.dataForm.comprobantesEstatus501C3 = [],
      this.currentImage = undefined;
      this.previewImage = undefined;
    },


    loadImageEstatus501C3(id){
      wsOrganization.getImage(id)
        .then(response => {
          this.imageEstatus501C3 = response.data;
          console.log("🚀 ~ file: organizationData.vue ~ line 887 ~ loadImageEstatus501C3 ~ this.imageEstatus501C3", this.imageEstatus501C3)
        })
        .catch(e => {
          console.log(e);
        });
    },



    onUpload(){
      this.loading = true
      const formData = new FormData()
      formData.append('files', this.currentImage)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.comprobantesEstatus501C3 = this.response_image.id
          this.$localStorage.set("img501C3", JSON.stringify(this.response_image))
          this.loading = false
          this.loaded = true

        }).catch((error)=>{
          this.errorsUpload = error
        })
    },


    deleteImage(id){
      wsOrganization.deleteFile(id).then(
        response => {
          this.removeImage = response.data.data;
          this.clearUpload()
          console.log("🚀 ~ file: organizationData.vue:978 ~ deleteImage ~ this.removeImage", this.removeImage)
        },
        error => {
          this.removeImage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log("🚀 ~ file: organizationData.vue:985 ~ deleteImage ~ this.removeImage", this.removeImage)
        }
      )
    },



    seedOrganization(){
      wsApplications.createOrganizacions({data:{application: this.$localStorage.get("appID")}}).then(console.log('✔✔'));
    },



  },

}
</script>