<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Vista Previa</h1>
        <hr class="red">
        <pre>{{uuid}}</pre>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-card>
          <v-tabs
            v-model="tab"
            background-color="verde"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              Datos de Organización
              <v-icon>mdi-account-group-outline</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              Datos del Representante
              <v-icon>mdi-account-tie</v-icon>
            </v-tab>
            <v-tab href="#tab-3">
              Mesa Directiva
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
              Relación de Miembros
              <v-icon>mdi-account-details</v-icon>
            </v-tab>
            <v-tab href="#tab-5">
              Coinversión
              <v-icon>mdi-sitemap</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <PreviewOrganizationData :appId="application.id" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat>
                <v-card-text>
                  <PreviewRepresentativeData :appId="application.id" /> 
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <v-card flat>
                <v-card-text>
                  <PreviewBoardDirectors :appId="application.id" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-4">
              <v-card flat>
                <v-card-text>
                  <PreviewMemberRelationship :appId="application.id" /> 
                </v-card-text>
              </v-card>
            </v-tab-item>
            
            <v-tab-item value="tab-5">
              <v-card flat>
                <v-card-text>
                  <PreviewCoInvestment :appId="application.id" />  
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-card>

        <pre>{{application}}</pre>

      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import PreviewOrganizationData from '@/components/previews/organizationData.vue'
import PreviewRepresentativeData from '@/components/previews/representativeData.vue'
import PreviewBoardDirectors from '@/components/previews/boardDirectors.vue'
import PreviewMemberRelationship from '@/components/previews/memberRelationship.vue'
import PreviewCoInvestment from '@/components/previews/coInvestment.vue'

import wsApplications from '@/services/application'

export default {
  name: 'PreviewComponent',
  
  components: {
    PreviewOrganizationData,
    PreviewRepresentativeData,
    PreviewBoardDirectors,
    PreviewMemberRelationship,
    PreviewCoInvestment,
  },

  data: () => ({

    application: [],
    tab: null,
    items: [
      { tab: 'Datos de Organización', content: 'Tab 1 Content' },
      { tab: 'Mesa Directiva', content: 'Tab 2 Content' },
      { tab: 'Relación de Miembros', content: 'Tab 3 Content' },
      { tab: 'Coinversión', content: 'Tab 4 Content' },
    ],
    
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){
    console.log('UUID => ', this.uuid_url)
    this.loadApplication(this.uuid_url)
  },
    

  computed: {
    
    currentUser() {
      return this.$store.state.auth.user;
    },

    uuid_url() {
      return this.$router.history.current.params.uuid
    },
      

  },

  methods:{
    
    loadApplication(id){
      wsApplications.findByUuid(id).then(
        response => {
          this.application = response.data.data;
          console.log("🚀 ~ file: Preview.vue:158 ~ loadApplication ~ this.application", this.application)
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    reeplace_str(string, rep, repWith) {
      const result = string.split(rep).join(repWith)
      return result; 
    },

  },

}
</script>