<template>
  <section class="rme-data">

    <span to="/dashboard" v-if="user_role === 'SysAdmin'">SysAdmin</span>
    <CromImeCertRmeDataComponent v-else-if="user_role === 'ime_certificador'" ></CromImeCertRmeDataComponent>
    <span to="/dashboard" v-else-if="user_role === 'ime_validador'">ime_validador</span>
    <span to="/dashboard" v-else-if="user_role === 'ime_observador'">ime_observador</span>
    <CromRmeCertRmeDataComponent v-else-if="user_role === 'rme_certificador'" ></CromRmeCertRmeDataComponent>
    <CromRmeValRmeDataComponent v-else-if="user_role === 'rme_validador'" ></CromRmeValRmeDataComponent>
    <CromOrgRmeDataComponent v-else-if="user_role === 'organizacion'" ></CromOrgRmeDataComponent>
    <CromAuthenticatedComponent v-else-if="user_role === 'Authenticated'" ></CromAuthenticatedComponent>
    <ProgressComponent v-else></ProgressComponent>

	</section>
</template>
<script>
import UserService from '@/services/user.service'
import ProgressComponent from '@/components/global/_Progress.vue'
import CromOrgRmeDataComponent from '@/components/roles/organizacion/crom/rmeData.vue'
import CromRmeValRmeDataComponent from '@/components/roles/rme_validador/crom/rmeData.vue'
import CromAuthenticatedComponent from '@/components/roles/Authenticated/crom/rmeData.vue'
import CromRmeCertRmeDataComponent from '@/components/roles/rme_certificador/crom/rmeData.vue'
import CromImeCertRmeDataComponent from '@/components/roles/ime_certificador/crom/rmeData.vue'

export default {
  name: 'RmeDataView',
  
  components: {
    ProgressComponent,
    CromOrgRmeDataComponent,
    CromRmeValRmeDataComponent,
    CromAuthenticatedComponent,
    CromRmeCertRmeDataComponent,
    CromImeCertRmeDataComponent
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted(){
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }


  },

  methods:{
    logOut() {
      this.$localStorage.remove('appID')
      this.$localStorage.remove('img501C3')
      this.$localStorage.remove('imgIden')
      this.$localStorage.remove('imgNac')
      this.$localStorage.remove('orgId')
      this.$localStorage.remove('orgType')
      this.$localStorage.remove('userRole')
      this.$localStorage.remove('rmeData')

      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    }
  },

}
</script>