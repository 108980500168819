import http from '@/services/http-common';
import authHeader from '@/services/auth-header';

class wsCoInvestment {
  getAll() {
    return http.get('/co-investments/?sort[0]=id&populate=*', { headers: authHeader() });
  }

  get(id) {
    return http.get(`/co-investments/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/co-investments", data, { headers: authHeader() });
  }
  
  update(id, data) {
    return http.put(`/co-investments/${id}`, data, { headers: authHeader() });
  }
  
  delete(id) {
    return http.delete(`/co-investments/${id}`, { headers: authHeader() });
  }
  
  findByUuid(uuid) {
    return http.get(`/co-investments?title=${uuid}`, { headers: authHeader() });
  }

  findByUser(userId) {
    return http.get(`/co-investments/?populate=*&filters[user][id][$eq]=${userId}`, { headers: authHeader() });
  }


  getImage(id) {
    return http.get(`/upload/files/${id}/?fields[0]=name&fields[1]=url`, { headers: authHeader() });
  }

  deleteFile(id) {
    return http.delete(`/upload/files/${id}`, { headers: authHeader() });
  }
  


}

export default new wsCoInvestment();