import http from '@/services/http-common';
import authHeader from '@/services/auth-header';

class wsRepresentatives {
  getAll() {
    return http.get('/representatives/?sort[0]=id&populate=*', { headers: authHeader() });
  }

  get(id) {
    return http.get(`/representatives/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/representatives", data, { headers: authHeader() });
  }
  
  update(id, data) {
    return http.put(`/representatives/${id}`, data, { headers: authHeader() });
  }
  
  delete(id) {
    return http.delete(`/representatives/${id}`, { headers: authHeader() });
  }
  
  deleteAll() {
    return http.delete(`/representatives`, { headers: authHeader() });
  }
  
  findByUuid(uuid) {
    return http.get(`/representatives?title=${uuid}`, { headers: authHeader() });
  }

}

export default new wsRepresentatives();