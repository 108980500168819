<template>
  <section class="endorsement">

    <v-col>
        <div v-if="user_role === 'SysAdmin'">SysAdmin</div>
        <div v-if="user_role === 'ime_certificador'">ime_certificador</div>
        <div v-if="user_role === 'ime_validador'">ime_validador</div>
        <div v-if="user_role === 'ime_observador'">ime_observador</div>
        <div v-if="user_role === 'rme_certificador'">rme_certificador</div>
        <div v-if="user_role === 'rme_validador'">rme_validador</div>
        <div v-if="user_role === 'organizacion'">
          <EndorsementComponent />
        </div>
        <div v-else>
          <ProgressComponent cfgColor="green" />
        </div>
      </v-col>

	</section>
</template>
<script>
import UserService from '@/services/user.service'
import ProgressComponent from '@/components/global/_Progress.vue'
import EndorsementComponent from '@/components/roles/organizacion/crom/endorsement.vue'

export default {
  name: 'EndorsementView',
  
  components: {
    ProgressComponent,
    EndorsementComponent,
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted(){
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }


  },

  methods:{
    logOut() {
      this.$localStorage.remove('appID')
      this.$localStorage.remove('img501C3')
      this.$localStorage.remove('imgIden')
      this.$localStorage.remove('imgNac')
      this.$localStorage.remove('orgId')
      this.$localStorage.remove('orgType')
      this.$localStorage.remove('userRole')
      this.$localStorage.remove('rmeData')
      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    }
  },

}
</script>