import http from '@/services/http-common';
import authHeader from '@/services/auth-header';

class wsMembers {
  getAll() {
    return http.get('/members/?sort[0]=id&populate=*', { headers: authHeader() });
  }

  getDirectorsByOrganization(uuid) {
    return http.get(`/members/?filters[esMesaDirectiva][$eq]=true&filters[application][uuid][$eq]=${uuid}`, { headers: authHeader() });
  }

  getMembersByOrganization(uuid) {
    return http.get(`/members/?filters[application][uuid][$eq]=${uuid}`, { headers: authHeader() });
  }

  get(id) {
    return http.get(`/members/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/members", data, { headers: authHeader() });
  }
  
  update(id, data) {
    return http.put(`/members/${id}`, data, { headers: authHeader() });
  }
  
  delete(id) {
    return http.delete(`/members/${id}`, { headers: authHeader() });
  }
  
  deleteAll() {
    return http.delete(`/members`, { headers: authHeader() });
  }
  
  findByUuid(uuid) {
    return http.get(`/members?uuid=${uuid}`, { headers: authHeader() });
  }

}

export default new wsMembers();