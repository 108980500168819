import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue';
import Login from '@/views/auth/Login.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue' 
import EmailConfirmation from '@/views/auth/EmailConfirmation.vue'
import Register from '@/views/auth/RegisterView.vue';

/*
 * Registro de Organizaciones
 */
import CromEndorsementView from '@/views/crom/endorsement.vue'
import CromDownloadEndorsementView from '@/views/crom/downloadEndorsement.vue'
import CromUploadDocumentComponent from '@/components/roles/Authenticated/crom/uploadDocument.vue'
//import CromRmeDataComponent from '@/components/roles/organizacion/crom/rmeData.vue'
import CromRmeDataView from '@/views/crom/rmeData.vue'
//import CromOrganizationDataComponent from '@/components/roles/organizacion/crom/organizationData.vue'
import CromOrganizationDataView from '@/views/crom/organizationData.vue'
//import CromRepresentativeDataComponent from '@/components/roles/organizacion/crom/representativeData.vue' 
import CromRepresentativeDataView from '@/views/crom/representativeData.vue' 
//import CromBoardDirectorsComponent from '@/components/roles/organizacion/crom/boardDirectors.vue'
import CromBoardDirectorsView from '@/views/crom/boardDirectors.vue'
import CromMemberRelationshipComponent from '@/components/roles/organizacion/crom/memberRelationship.vue'
import CoInvestmentProjectDataComponent from '@/components/roles/organizacion/co-investment/projectData.vue'
import CromDocumentsComponent from '@/components/roles/organizacion/crom/documents.vue'

import CromDownloadCertificateView from '@/views/crom/downloadCertificate.vue'


/*
 * RME Validators
 */
import SignCertificatorView from '@/views/crom/sign.vue'

/*
 * Vista previa
 */
import PreviewComponent from '@/components/roles/Preview.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },


  /* Auth */
  {
    path: '/auth/login/:m?',
    name: 'AuthLogin',
    component: Login
  },
  {
    path: '/auth/register',
    name: 'AuthRegister',
    component: Register
  },
  {
    path: '/auth/reset-password',
    name: 'AuthResetPassword',
    component: ResetPassword,
  },
  {
    path: '/auth/email-confirmation/:token?',
    name: 'EmailConfirmation',
    component: EmailConfirmation,
  },


  /* User > Profile */
  {
    path: '/user/profile',
    name: 'profile',
    component: () => import('@/views/auth/Profile.vue')
  },


  /* Dashboard */
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue')
  },

  /* Vista Previa */
  {
    path: '/dashboard/preview/:uuid',
    name: 'DataPreview',
    component: PreviewComponent,
  },




  /* Registro */
  {
    path: '/crom/endorsement/:uuid',
    name: 'CromEndorsement',
    component: CromEndorsementView,
  },
  {
    path: '/crom/download-endorsement/:uuid',
    name: 'CromDownloadEndorsement',
    component: CromDownloadEndorsementView,
  },
  {
    path: '/crom/upload-endorsement/:uuid',
    name: 'CromUploadDocument',
    component: CromUploadDocumentComponent,
  },
  {
    path: '/crom/rme-data/:uuid',
    name: 'CromRmeData',
    component: CromRmeDataView,
  },
  {
    path: '/crom/organization-data/:uuid',
    name: 'CromOrganizationData',
    component: CromOrganizationDataView,
  },
  {
    path: '/crom/representative-data/:uuid',
    name: 'CromRepresentativeData',
    component: CromRepresentativeDataView,
  },
  {
    path: '/crom/board-directors/:uuid',
    name: 'CromBoardDirectors',
    component: CromBoardDirectorsView,
  },
  {
    path: '/crom/member-relationship/:uuid',
    name: 'CromMemberRelationship',
    component: CromMemberRelationshipComponent,
  },
  {
    path: '/co-investment/project-data/:uuid',
    name: 'CoInvestmentProjectData',
    component: CoInvestmentProjectDataComponent,
  },
  {
    path: '/crom/documents/:uuid',
    name: 'CromDocuments',
    component: CromDocumentsComponent,
  },

  {
    path: '/crom/download-certificate/:uuid',
    name: 'CromDownloadCertificate',
    component: CromDownloadCertificateView,
  },


  /* FIRMAS */
  {
    path: '/sign/:uuid',
    name: 'SignCertificator',
    component: SignCertificatorView,
  },





  /* Pages */
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/auth/login',
    '/auth/register',
    '/auth/reset-password',
    '/auth/email-confirmation'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/auth/login');
  } else {
    next();
  }
});


export default router
