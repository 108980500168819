<template>
  <v-container>
    <v-row>

      <v-col style="display:none">
        <div ref="htmlToPdf" style="margin: 25px 40px;width:385px;">
          <center><h1>Carta de Solicitud de Garantía de existencia para proceso de registro de organización migrante.</h1></center>
          <p>&nbsp;</p>
          <p>El {{ application.organizationType }} <strong>{{ organization.nombreOrganizacion }}</strong>, representado en este acto por el/la <strong>C.________________________</strong>, en su carácter de Presidente(a),
            solicita el apoyo del Consulado de México en {{ application.e_rme }}, para conforme a lo dispuesto en  los  artículos  cuarenta y cuatro fracción IV de  la Ley del  Servicio Exterior  Mexicano, 
            treinta y dos y ochenta y siete de su Reglamento, actuando bajo sus funciones de Notario Público, haga constar la existencia de esta Organización, para los efectos requeridos en el 
            Sistema de Registro de Organizaciones Migrantes (CROM), administrado por el Instituto de los Mexicanos en el Exterior, Órgano Desconcentrado de la Secretaría de Relaciones Exteriores,
            conforme a los siguientes considerandos: </p>
          <p>&nbsp;</p>
          <p>1)	<strong>{{ organization.nombreOrganizacion }}</strong>, es un {{ application.organizationType }} constituido legalmente, tal como se establece en el Acta Número _____________ emitida por ________________.</p>
          <p>&nbsp;</p>
          <p>2)	<strong>{{ organization.nombreOrganizacion }}</strong>,  es un {{ application.organizationType }} que cuenta con registro 501c3 aprobado por el Internal Revenue Service (ISR). </p>
          <p>&nbsp;</p>
          <p>3)	<strong>{{ organization.nombreOrganizacion }}</strong>, se encuentra registrado legalmente ante la Secretaría del Estado de {{ organization.stateExterior.name }}.</p>
          <p>&nbsp;</p>
          <p>4)	<strong>{{ organization.nombreOrganizacion }}</strong>, se integra por <strong>{{ organization.numeroMiembros }}</strong> participantes que acreditan legalmente su nacionalidad mexicana. </p>
          <p>&nbsp;</p>
          <p>5)	El {{ application.organizationType }} <strong>{{ organization.nombreOrganizacion }}</strong>, se ubica físicamente en:
            {{ organization.calle }}, {{ organization.numeroExterior }}, {{ organization.numeroInterior }}, 
            {{ organization.townshipExterior ? organization.townshipExterior.name : '--' }}, {{ organization.stateExterior.name }}, {{ organization.countryExterior.name }}
          .</p>
          <p>&nbsp;</p>
          <p>Cabe mencionar que la documentación referida en el párrafo anterior, se adjuntará en el CROM, y se mantendrá disponible durante la vigencia del Registro para consulta y 
            cotejo por parte de esa Representación de México, utilizando la presente para declarar bajo protesta de decir verdad que los documentos son auténticos y la información proporcionada es veraz, 
            asumiendo en todo momento la responsabilidad legal que a derecho corresponda, en caso de demostrarse lo contrario.  </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <center>
            <p>ATENTAMENTE</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Nombre y Firma (Autógrafa)</p>
            <p>Presidente(a) del {{ application.organizationType }} {{ organization.nombreOrganizacion }}</p>
          </center>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <table border="0" style="width: 380px !important;">
            <tr>
              <td width="190" class="small">||Firmante:Consulmex+Orlando|61a6d805-72ec-4ccd-b5cd-466c0bbed18f|MXORG103|julietam|federacion|Organizacion+Migrante+en+el+Exterior||</td>
            </tr>
            <tr>
              <td width="190" class="small">fHxGaXJtYW50ZTpDb25zdWxtZXgrT3JsYW5kb3w2MWE2ZDgwNS03MmVjLTRjY2QtYjVjZC00NjZjMGJiZWQxOGZ8TVhPUkcxMDN8anVsaWV0YW18ZmVkZXJhY2lvbnxPcmdhbml6YWNpb24rTWlncmFudGUrZW4rZWwrRXh0ZXJpb3J8fA==</td>
            </tr>
          </table>

        </div>
      </v-col>
      
      <v-col>
        <v-btn x-large color="dorado-obscuro" dark @click="generatePdf(application.uuid)">
          <v-icon left>mdi-file-pdf-box</v-icon>
          Descargar Formato
        </v-btn>
      </v-col>
    
    </v-row>
  </v-container>
</template>
<script>
//import VueHtml2pdf from 'vue-html2pdf'
import { jsPDF } from "jspdf";

import wsAddress from "@/services/address"
import wsOrganization  from "@/services/organizations"
import wsApplications from '@/services/application'
//import PdfComponent from '@/components/previews/pdf.vue'

export default {
  components: {
    //VueHtml2pdf,
    //PdfComponent
  },

  data: () => ({
    application: [],
    organization:{
      townshipExterior: {},
      stateExterior: {},
      countryExterior: {}
    },
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    userProfile(){
      var result = JSON.parse(this.$localStorage.get("userProfile"))
      return result;
    },
    currentOrganization(){
      const result = this.organization.id
      return result;
    },
    routeUuid(){
      return this.$route.params.uuid;
    }
  },

  mounted(){
    this.loadApplication(this.routeUuid)
    //this.loadOrganization(this.appID.id)
    //this.generateReport()
  },

  methods: {

    generatePdf(uuid){
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
        compress: "true",
        precision: 4,
        putOnlyUsedFonts:true
      });
      //doc.addFont("Montserrat.ttf", "Monserrat", "normal");
      doc.html(this.$refs.htmlToPdf, {
        callback: function (doc) {
          doc.save('carta-validacion_'+ uuid +'.pdf');
        },
        x: 0,
        y: 0
      });


    },



    loadApplication(uuid){
      wsApplications.findByUuidAndOrganization(uuid).then(
        response => {
          this.application = response.data.data;
          this.organization = response.data.data.organization;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadOrganization(id){
      wsOrganization.get(id).then(
        response => {
          this.organization = response.data.data;
        },
        error => {
          this.organization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },


  },


}
</script>
<style scoped>
h1 {
  font-size: 12px;
  margin: 20px 0 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
  letter-spacing: -0.015em;
}
p{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif,verdana, arial, helvetica;
  font-size: 8px;
  word-spacing: 0.1em;
  letter-spacing: -0.005em;

}
.small{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif,verdana, arial, helvetica;
  font-size: 5px;
  word-spacing: 0.1em;
  letter-spacing: -0.025em;
  display: inline;
  background-color: bisque;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>