import http from '@/services/http-common';
import authHeader from '@/services/auth-header';

class wsApplications {
  getAll() {
    return http.get('/applications/?sort[0]=id&populate=*', { headers: authHeader() });
  }

  get(id) {
    return http.get(`/applications/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/applications", data, { headers: authHeader() });
  }
  
  update(id, data) {
    return http.put(`/applications/${id}`, data, { headers: authHeader() });
  }
  
  delete(id) {
    return http.delete(`/applications/${id}`, { headers: authHeader() });
  }
  
  findByUuid(uuid) {
    return http.get(`/slugify/slugs/application/${uuid}/?populate=*`, { headers: authHeader() });
  }

  findByUuidAndOrganization(uuid) {
    return http.get(`/slugify/slugs/application/${uuid}/?populate=deep,3`, { headers: authHeader() });
  }

  findByUser(userId) {
    return http.get(`/applications/?populate=*&filters[user][id][$eq]=${userId}`, { headers: authHeader() });
  }

  findById(id) {
    return http.get(`/applications/?populate=*&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }

  getMyApplication(userId) {
    return http.get(`/applications/?populate=*&filters[user][id][$eq]=${userId}`,{ headers: authHeader() });
  }
  
  getMyApplicationStatus(userId) {
    return http.get(`/applications/?populate[0]=status&populate[1]=organization&filters[user][id][$eq]=${userId}`,{ headers: authHeader() });
  }
  
  getMyApplicationStatusS(userId) {
    return http.get(`/applications/?populate[status][fields][0]=rmeData&populate[status][fields][1]=organizationData&populate[status][fields][2]=representativeData&populate[status][fields][3]=boardDirectors&populate[status][fields][4]=memberRelationship&populate[status][fields][5]=coInvestment&populate[status][fields][6]=inValidation&populate[status][fields][7]=signRme&populate[status][fields][8]=signIime&populate[status][fields][9]=certificate&filters[user][id][$eq]=${userId}`,{ headers: authHeader() });
  }

  getMyApplicationTable(rmeId) {
    return http.get(`/applications/?populate[status][fields][0]=*&populate[organization][fields][2]=id,numeroFolio,tipoSolicitud,nombreOrganizacion,isFederation&filters[e_rme][$eq]=${rmeId}&filters[$or][0][status][inValidation][$eq]=true&filters[$or][1][status][endorsementUpload][$eq]=true`, { headers: authHeader() });
  }

  getApplicationsTable() {
    return http.get(`/applications/?populate[organization][fields][0]=id,numeroFolio,tipoSolicitud,nombreOrganizacion&filters[status][signRme][$eq]=true`, { headers: authHeader() });
  }

  getMyApplicationOrganizacion(orgId) {
    return http.get(`/organizations/?populate=*&filters[e_rme][$eq]=${orgId}`, { headers: authHeader() });
  }

  getApplicationOr(id) {
    return http.get(`/applications/${id}?populate[organization][fields][1]=id&populate[organization][fields][2]=ambitoActividad`, { headers: authHeader() });
  }

  getApplicationFull(id) {
    return http.get(`/applications/${id}/?populate[organization][fields][0]=id`, { headers: authHeader() });
  }

  getApplication(id) {
    return http.get(`/applications/${id}/?populate[organization][fields][0]=id,nombreOrganizacion`, { headers: authHeader() });
  }

  getApplicationByOrganizacionType(organizationType) {
    return http.get(`/applications/?filters[organizationType][$eq]=${organizationType}&fields[0]=uuid,organizationType,e_rme&populate[organization][fields][1]=nombreOrganizacion`, { headers: authHeader() });
  }



  /* Solicitudes */  
  getOrganizationFull(id) {
    return http.get(`/organizations/?populate[0]=comprobantesEstatus501C3&populate[1]=countryExterior&populate[2]=stateExterior&populate[3]=townshipExterior&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }
  getRepresentativesFull(id) {
    return http.get(`/representatives/?populate=*&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }
  getBoardDirectorsFull(id) {
    return http.get(`/members/?populate[0]=comprobanteNacionalidad&populate[1]=state&populate[2]=township&filters[esMesaDirectiva][$eq]=true&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }
  getMembersFull(id) {
    return http.get(`/members/?populate[0]=comprobanteNacionalidad&populate[1]=state&populate[2]=township&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }
  getCoInvestmentFull(id) {
    return http.get(`/co-investments/?populate=*&filters[application][id][$eq]=${id}`, { headers: authHeader() });
  }


  /* Federaciones */  
  getAllFederations() {
    return http.get('/federations/?populate[logo][fields][0]=name,url,width,height&filters[isActive][$eq]=true', { headers: authHeader() });
  }
  getFederationAll(uuid) {
    return http.get('/federations/?populate[logo][fields][0]=name,url,width,height', { headers: authHeader() });
  }
  getFederationUuid(uuid) {
    return http.get(`/slugify/slugs/federation/${uuid}`, { headers: authHeader() });
  }
  createFederation(data) {
    return http.post('/federations/', data, { headers: authHeader() });
  }
  updateFederation(id,data) {
    return http.put(`/federations/${id}`, data, { headers: authHeader() });
  }


  /* Clubes */
  getAllClubs() {
    return http.get('/clubs/', { headers: authHeader() });
  }
  getClubUuid(uuid) {
    return http.get(`/slugify/slugs/club/${uuid}`, { headers: authHeader() });
  }
  createClub(data) {
    return http.post('/clubs/', data, { headers: authHeader() });
  }
  updateClub(id,data) {
    return http.put(`/clubs/${id}`, data, { headers: authHeader() });
  }



  /* Carta Aval */
  getAllEndorsements() {
    return http.get(`/endorsements/`, { headers: authHeader() });
  }
  getEndorsement(id) {
    return http.get(`/endorsements/${id}`, { headers: authHeader() });
  }
  createEndorsement(data) {
    return http.post('/endorsements/', data, { headers: authHeader() });
  }
  getEndorsementByApplication(appId) {
    return http.get(`/endorsements/?fields[0]=isValid&populate[document][fields][1]=name,url&filters[application][id][$eq]=${appId}`, { headers: authHeader() });
  }
  
  



  /* Semillas */  
  createApplication(data) {
    return http.post("/applications", data, { headers: authHeader() });
  }
  createOrganizacions(data) {
    return http.post("/organizations", data, { headers: authHeader() });
  }
  createRepresentatives(data) {
    return http.post("/representatives", data, { headers: authHeader() });
  }
  createCoInvestment(data) {
    return http.post("/co-investments", data, { headers: authHeader() });
  }



}

export default new wsApplications();