<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Coinversión</h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>

        <v-col col md="4" sm="6" xs="12">
          <v-text-field
            filled
            disabled
            :label="organization.nombreOrganizacion"
            :value="organization.nombreOrganizacion"
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-select
            :items="projectHub"
            filled
            label="Eje del proyecto"
            prepend-icon="mdi-playlist-check"
            v-model="dataForm.ejeProyecto"
          ></v-select>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-select
            :items="financialMechanism"
            filled
            label="Mecanismo de financiamiento"
            prepend-icon="mdi-hand-coin"
            v-model="dataForm.mecanismoFinanciamiento"
          ></v-select>
        </v-col>

      
        <v-col col md="12" class="">
          <h2>Datos generales del proyecto</h2>
        </v-col>

        <v-col col md="12">
          <v-text-field
            filled
            label="Nombre del Proyecto"
            prepend-icon="mdi-form-textbox"
            v-model="dataForm.nombreProyecto"
            :rules="rules.nombreProyecto"
          ></v-text-field>
        </v-col>

        <v-col col md="12">
          <v-textarea
            filled
            label="Propósito del proyecto"
            auto-grow
            prepend-icon="mdi-text-long"
            v-model="dataForm.propositoProyecto"
          ></v-textarea>
        </v-col>

        <v-col col md="12">
          <v-textarea
            filled
            label="Problema que busca resolver"
            auto-grow
            prepend-icon="mdi-text-long"
            v-model="dataForm.problemaResolver"
          ></v-textarea>
        </v-col>


        <v-col col md="12">
          <v-textarea
            filled
            label="Beneficios a la población local"
            auto-grow
            prepend-icon="mdi-text-long"
            v-model="dataForm.beneficiosPoblacion"
          ></v-textarea>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <p>¿El proyecto ha sido presentado anteriormente?: </p>
          <v-radio-group
            v-model="dataForm.proyectoAnterior"
            mandatory
            row
          >
            <v-radio
              label="Si"
              value="true"
            ></v-radio>
            <v-radio
              label="No"
              value="false"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col col md="4" sm="6" xs="12">
          <v-text-field
            :disabled="dataForm.proyectoAnterior === 'false'"
            filled
            label="Folio anterior"
            prepend-icon="mdi-counter"
            v-model="dataForm.folioAnterior"
          ></v-text-field>
        </v-col>

        <v-col col md="4" sm="6" xs="12">
          <v-text-field
            filled
            label="Número aproximado de beneficiarios que tendrá el proyecto"
            prepend-icon="mdi-numeric"
            v-model="dataForm.numeroBeneficiarios"
          ></v-text-field>
        </v-col>



        <v-col col md="12" class="">
          <h2>Datos generales del representante de la Organización en México</h2>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Nombre(s): "
            prepend-icon="mdi-account"
            v-model="dataForm.nombre"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Primer Apellido: "
            prepend-icon="mdi-account"
            v-model="dataForm.apellidoPaterno"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Segundo Apellido: "
            prepend-icon="mdi-account"
            v-model="dataForm.apellidoMaterno"
          ></v-text-field>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.stateBirth"
            :items="statesBirth"
            item-text="name"
            item-value="id"
            filled
            label="Entidad de nacimiento:"
            prepend-icon="mdi-earth"
            @change="loadTownsBirth"
            no-data-text="Selecciona tu nacionalidad"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.townshipBirth"
            :items="townsBirth"
            item-text="name"
            item-value="id"
            filled
            label="Municipio de nacimiento:"
            prepend-icon="mdi-earth"
            no-data-text="Selecciona un estado"
            :disabled="!dataForm.stateBirth"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataForm.fechaNacimiento"
                label="Fecha de nacimiento"
                prepend-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataForm.fechaNacimiento"
              :active-picker.sync="activePicker"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
              locale="es-mx"
            ></v-date-picker>
          </v-menu>
        </v-col>


        <v-col col md="4" sm="6" xs="12" class="">
          <v-select
            :items="sexo"
            filled
            label="Sexo:"
            prepend-icon="mdi-human-male-female"
            v-model="dataForm.sexo"
          ></v-select>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="CURP: "
            prepend-icon="mdi-form-textbox"
            v-model="dataForm.curp"
            :rules="rules.curp"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>




        <v-col col md="12" class="">
          <h2>Domicilio:</h2>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.countryRepresentante"
            :items="countries"
            item-text="name"
            item-value="id"
            filled
            label="País:"
            prepend-icon="mdi-earth"
            @change="loadStates"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.stateRepresentante"
            :items="states"
            item-text="name"
            item-value="id"
            filled
            label="Estado/Provincia:"
            prepend-icon="mdi-earth"
            @change="loadTowns"
            no-data-text="Selecciona un país"
          ></v-autocomplete>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.townRepresentante"
            :items="towns"
            item-text="name"
            item-value="id"
            filled
            label="Condado/Distrito:"
            prepend-icon="mdi-earth"
            no-data-text="Selecciona un estado"
          ></v-autocomplete>
        </v-col>

        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Ciudad:"
            prepend-icon="mdi-earth"
            v-model="dataForm.ciudad"
          ></v-text-field>
        </v-col>
        <v-col col md="8" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Calle:"
            prepend-icon="mdi-map-marker"
            v-model="dataForm.calle"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Codigo Postal:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.codigoPostal"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Número exterior:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.numeroExterior"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Número interior:"
            prepend-icon="mdi-numeric"
            v-model="dataForm.numeroInterior"
          ></v-text-field>
        </v-col>


        <v-col col md="12" class="">
          <h2>Datos de contacto:</h2>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Correo electrónico:"
            prepend-icon="mdi-at"
            v-model="dataForm.correoElectronico"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
  
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono fijo:"
            prepend-icon="mdi-phone"
            v-model="dataForm.telefonoFijo"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Teléfono móvil:"
            prepend-icon="mdi-cellphone-basic"
            v-model="dataForm.telefonoMovil"
          ></v-text-field>
        </v-col>


        <v-col col md="12" class="">
          <h2>Redes Sociales:</h2>
          <small class="">Indicar solo el Usuario</small>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Twitter:"
            prepend-icon="mdi-twitter"
            v-model="dataForm.twitter"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Facebook:"
            prepend-icon="mdi-facebook"
            v-model="dataForm.facebook"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Tiktok:"
            prepend-icon="mdi-alpha-t-circle-outline"
            v-model="dataForm.tiktok"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Instagram:"
            prepend-icon="mdi-instagram"
            v-model="dataForm.instagram"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
          <v-text-field
            filled
            label="Otra red social:"
            prepend-icon="mdi-account-switch"
            v-model="dataForm.otraRed"
          ></v-text-field>
        </v-col>
        <v-col col md="4" sm="6" xs="12" class="">
        </v-col>


      </v-row>


      <v-row>
        <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
          <v-btn
            :disabled="!valid"
            x-large
            color="red darken-4"
            dark
            class="float-right"
            @click="infoPreview"
          >
            Guardar Datos del Proyecto
            <v-icon
              right
              dark
            >
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

    </v-form>




    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="dorado-obscuro"
            >
              <v-btn
                class="rojo-obscuro"
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-uppercase strong">Vista previa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  x-large
                  color="verde"
                  dark
                  @click="saveProjectData"
                >
                  Guardar
                  <v-icon
                    right
                    dark
                  >
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-title class="text-h5">
              {{selectedItem.nombreOrganizacion}}
            </v-card-title>

            <v-card-text>

              <div>
                <v-alert type="success" v-show="alertSuccess">
                  Registro Guardado
                </v-alert>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col" colspan="3" class="">
                        <pre>{{selectedItem}}</pre>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions class="dorado">
              <v-spacer></v-spacer>

              <v-btn
                x-large
                dark
                color="green"
                @click="dialog = false"
              >
                Continuar Modificando
                <v-icon
                  right
                  dark
                >
                  mdi-pencil
                </v-icon>
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-alert border="top" dark type="success" v-show="alertSuccess">
          Registro Guardado
        </v-alert>
        <v-alert border="top" dark type="error" v-show="alertError">
          Error al  Guardar
        </v-alert>
        <v-alert border="top" dark color="red lighten-2" v-show="warningError">
          Hay campos sin llenar
        </v-alert>

        <v-snackbar
          v-model="snackbarWarning"
          :timeout="timeout"
          :multi-line="multiLine"
          absolute
          centered
          elevation="10"
          color="deep-orange darken-3"
        >
          <h3 class="white--text">Hay campos sin llenar</h3>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white orange--text text--darken-4"
              fab
              v-bind="attrs"
              @click="snackbarWarning = false"
              small
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>


      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <pre>{{ dataForm }}</pre>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import wsAddress from "@/services/address.js";
import wsApplications from '@/services/application'
import wsCoInvestment from '@/services/coinvestment'

export default {
  name: 'CoInvestmentComponent',

  props: {
    uuidV4: String
  },

  components: {
  },

  data: () => ({
    valid: true,
    multiLine: true,
    uuidOrganization: '',
    organization: [],
    selectedItem: [],
    
    countries: [],
    states: [],
    towns: [],
    cities: [],
    statesBirth: [],
    townsBirth: [],

    dialog: false,
    alertSuccess: false,
    alertError: false,
    warningError: false,
    
    activePicker: null,
    date: null,
    menu: false,

    snackbar: false,
    snackbarWarning: false,
    timeout: 5000,

    dataForm:{
      ejeProyecto: '',
      mecanismoFinanciamiento: '',
      nombreProyecto: '',
      propositoProyecto: '',
      problemaResolver: '',
      beneficiosPoblacion: '',
      proyectoAnterior: 'false',
      folioAnterior: '',
      numeroBeneficiarios:'',
      nombre: "Marcos",
      apellidoPaterno: "Garcia",
      apellidoMaterno: "Bello",
      stateBirth: null,
      townshipBirth: null,
      fechaNacimiento: "",
      sexo: "",
      curp: "GABJ820624HDFRLN",
      countryRepresentante: null, //Relation
      stateRepresentante: null, //Relation
      townRepresentante:null, //Relation
      calle:'',
      codigoPostal: '',
      ciudad: '',
      numeroExterior: '',
      numeroInterior: '',
      correoElectronico: 'jmarcosgb@gmail.com',
      telefonoFijo: '',
      telefonoMovil: '',
      twitter: '',
      facebook: '',
      tiktok: '',
      instagram: '',
      otraRed: '',
      application: null,
    },
    projectHub: [
      {
        value: 'infraestructura',
        text: 'Infraestructura'
      },
      {
        value: 'mejoramiento-urbano',
        text: 'Mejoramiento urbano'
      },
      {
        value: 'proyecto-productivo',
        text: 'Proyecto productivo'
      },
    ],

    financialMechanism: [
      {
        value: 'aportaciones-de-los-integrantes',
        text: 'Aportaciones de los integrantes'
      },
      {
        value: 'eventos-de-recaudación',
        text: 'Eventos de recaudación'
      },
      {
        value: 'donativos',
        text: 'Donativos'
      },
    ],

    sexo: [
      {
        value: '0',
        text: 'Selecciona...'
      },
      {
        value: 'hombre',
        text: 'Hombre'
      },
      {
        value: 'mujer',
        text: 'Mujer'
      },
      {
        value: 'no-binario',
        text: 'No-binario'
      },
      {
        value: 'otro',
        text: 'Otro'
      },
    ],

    rules: {
      nombreProyecto: [
        value => !!value || 'Este campo es requerido',
      ],
      cargoIntegrante: [
        value => !!value || 'Este campo es requerido',
      ],
      curp: [
        value => !!value || 'Este campo es requerido',
        value => /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(value.toUpperCase()) || ' no es una CURP valida',
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },



  }),

  mounted(){
    this.loadStatesBirth(42);
    this.loadTownsBirth( this.dataForm.stateBirth ? this.dataForm.stateBirth : 0 );

    this.loadCountries();
    this.loadStates( this.dataForm.countryIntegrante ? this.dataForm.countryIntegrante : 0 );
    this.loadTowns( this.dataForm.stateIntegrante ? this.dataForm.stateIntegrante : 0 );
    this.loadCities( this.dataForm.stateIntegrante ? this.dataForm.stateIntegrante : 0 );

    this.setOrg = JSON.parse(this.$localStorage.get("orgId"))
    this.organization = this.setOrg.organization
  },

  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  methods:{

    saveProjectData(){
      //this.dataFormData = this.dataForm
      var dataStorage = {
        ejeProyecto: this.dataForm.ejeProyecto,
        mecanismoFinanciamiento: this.dataForm.mecanismoFinanciamiento,
        nombreProyecto: this.dataForm.nombreProyecto,
        propositoProyecto: this.dataForm.propositoProyecto,
        problemaResolver: this.dataForm.problemaResolver,
        beneficiosPoblacion: this.dataForm.beneficiosPoblacion,
        proyectoAnterior: this.dataForm.proyectoAnterior,
        folioAnterior: this.dataForm.folioAnterior,
        numeroBeneficiarios:this.dataForm.numeroBeneficiarios,
        nombre: this.dataForm.nombre,
        apellidoPaterno: this.dataForm.apellidoPaterno,
        apellidoMaterno: this.dataForm.apellidoMaterno,
        stateBirth: this.dataForm.stateBirth,
        townshipBirth: this.dataForm.townshipBirth,
        fechaNacimiento: this.dataForm.fechaNacimiento,
        sexo: this.dataForm.sexo,
        curp: this.dataForm.curp,
        countryRepresentante: this.dataForm.countryRepresentante, //Relation
        stateRepresentante: this.dataForm.stateRepresentante, //Relation
        townRepresentante:this.dataForm.townRepresentante, //Relation
        calle:this.dataForm.calle,
        codigoPostal: this.dataForm.codigoPostal,
        ciudad: this.dataForm.ciudad,
        numeroExterior: this.dataForm.numeroExterior,
        numeroInterior: this.dataForm.numeroInterior,
        correoElectronico: this.dataForm.correoElectronico,
        telefonoFijo: this.dataForm.telefonoFijo,
        telefonoMovil: this.dataForm.telefonoMovil,
        twitter: this.dataForm.twitter,
        facebook: this.dataForm.facebook,
        tiktok: this.dataForm.tiktok,
        instagram: this.dataForm.instagram,
        otraRed: this.dataForm.otraRed,
        application: JSON.parse(this.$localStorage.get("appID")),
        nombreOrganizacion: this.organization.nombreOrganizacion
      }

      wsCoInvestment.create({data:dataStorage}).then(
        response => {
          this.projectData = response.data.data;
          this.loading = true
          this.updateApplication()
          this.$router.push('/dashboard/')
        },
        error => {
          this.projectData =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )

    },

    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          organizationData: true,
          representativeData: true,
          boardDirectors: true,
          memberRelationship: true,
          coInvestment: true,
          inValidation: true
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },



    loadCountries() {
      wsAddress.getAllCountries()
        .then(response => {
          this.countries = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadStates(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.states = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTowns(id) {
      wsAddress.getTownByState(id)
        .then(response => {
          this.towns = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });

      wsAddress.getCityByState(id)
        .then(response => {
          this.cities = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadCities(id) {
      wsAddress.getCityByState(id)
        .then(response => {
          this.cities = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadStatesBirth(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.statesBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTownsBirth(id) {
      wsAddress.getTownByState(id)
        .then(response => {
          this.townsBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    save(date) {
      this.$refs.menu.save(date)
    },

    infoPreview() {
      this.$refs.form.validate();
      if(this.$refs.form.validate() === true){
        this.dialog = true
        this.selectedItem = this.dataForm;
        this.uuidOrganization = this.$route.params.uuid;
      }else{
        this.snackbarWarning = true
      }

    },

  },

}
</script>
