import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ENDPOINT;

class UserService {

  /*
   * Mi Perfil
   */
  getUserProfile(id) {
    return axios.get(API_URL + `/profiles/?populate[avatar][fields][0]=name,url&filters[user][id][$eq]=${id}`, { headers: authHeader() });
  }
  createUserProfile(data) {
    return axios.post(API_URL + '/profiles/', data, { headers: authHeader() });
  }
  loadUserRole() {
    return axios.get(API_URL + '/users/me', { headers: authHeader() });
  }
  updateMyProfile(id, data) {
    return axios.put(API_URL + `/profiles/${id}`, data, { headers: authHeader() });
  }


  /*
   * Solicitudes (Perfil Organizaciones)
   */
  getMyApplication(id) {
    return axios.get(API_URL + `/applications/?populate=*&filters[user][id][$eq]=${id}`,{ headers: authHeader() });
  }
  getMyApplicationStatus(id) {
    return axios.get(API_URL + `/applications/?populate[0]=organization__data
    &populate[1]=representative__data
    &populate[2]=board__directors
    &populate[3]=member__relationship
    &populate[4]=co__investment
    &populate[5]=in__validation
    &populate[6]=sign__consul
    &populate[7]=sign__ime
    &filters[user][id][$eq]=${id}`,{ headers: authHeader() });
  }
  getMyApplicationTable(id) {
    return axios.get(API_URL + `/applications/?populate[organization][fields][0]=numeroFolio
    &populate[organization][fields][1]=fechaSolicitud
    &populate[organization][fields][2]=nombreOrganizacion
    &populate[organization][fields][3]=tipoSolicitud
    &populate[user][fields][3]=email
    &filters[user][id][$eq]=${id}`, { headers: authHeader() });
  }

}

export default new UserService();
