import axios from 'axios';
const API_URL = process.env.VUE_APP_ENDPOINT_RMES;

class wsExternal{

  loadRepresentations() {
    return axios.get(API_URL + '/representations/?_limit=30');
  }

  loadTypeRepresentations() {
    return axios.get(API_URL + '/type-representations/?id_in=3&id_in=7');
  }

  loadRepresentation(id) {
    return axios.get(API_URL + `/representations/${id}`);
  }

  loadRepresentationsbyCountry(countryId) {
    return axios.get(API_URL + `/representations/?country=${countryId}`);
  }

  loadRepresentationsbyRmeType(rmeTypeId) {
    return axios.get(API_URL + `/representations/?type_representation=${rmeTypeId}`);
  }

  authForgotPassword(data) {
    return axios.post("/auth/forgot-password", data);
  }

}

export default new wsExternal();