<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Relación de Miembros</h1>
        <hr class="red">
      </v-col>
    </v-row>


    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>

        <v-col col md="4" sm="6" xs="12">
          <v-text-field
            filled
            label="Nombre Completo:"
            prepend-icon="mdi-account"
            v-model="dataForm.nombreCompleto"
            :rules="rules.required"
          ></v-text-field>
        </v-col>

        <v-col col md="2" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.stateMember"
            :items="statesBirth"
            item-text="name"
            item-value="id"
            filled
            label="Estado:"
            prepend-icon="mdi-earth"
            @change="loadTownsBirth"
            no-data-text="Selecciona tu nacionalidad"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>

        <v-col col md="2" sm="6" xs="12" class="">
          <v-autocomplete
            v-model="dataForm.townshipMember"
            :items="townshipsBirth"
            item-text="name"
            item-value="id"
            filled  
            label="Municipio:"
            prepend-icon="mdi-earth"
            no-data-text="Selecciona un estado"
            :rules="rules.required"
          ></v-autocomplete>
        </v-col>

        <v-col col md="3" sm="6" xs="12" class="">
        
          <v-avatar
            v-if="urlComprobante"
            size="60"
            color="blue-grey darken-3">
            <img
              :src="`${path.storage_files}${urlComprobante}`"
              :alt="`${nameComprobante}`"
            >
          </v-avatar>

          <v-file-input
            v-else
            show-size
            label="Documento que acredite la nacionalidad mexicana:"
            :rules="rules.uploadNacionalidad"
            @change="selectImageNacionalidad"
            accept="image/jpeg,image/png,application/pdf"
            v-model="inputFile"
          ></v-file-input>

          <div v-if="previewImageNacionalidad">
            <div v-if="currentImageNacionalidad.type === 'image/jpeg' || currentImageNacionalidad.type === 'image/png'">
              <v-img
                :lazy-src="previewImageNacionalidad"
                :src="previewImageNacionalidad"
                contain
              ></v-img>
            </div>
            <div v-else-if="currentImageNacionalidad.type === 'application/pdf'">
              <v-icon size="75">mdi-file-pdf-box</v-icon>
            </div>
            <div v-else>OTRO Archivo</div>

            <v-btn
              class="mx-2 my-2 float-left"
              fab
              dark
              small
              color="yellow darken-4"
              @click="onUpload"
            >
              <v-icon dark>mdi-upload</v-icon>
            </v-btn>

            <v-btn
              class="mx-2 my-2 float-right"
              fab
              dark
              small
              color="red"
              @click="clearNacionalidad()"
            >
              <v-icon dark>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </div>

        </v-col>
        <v-col col md="1" sm="6" xs="12" class="">
          <v-btn
            fab
            dark
            color="green darken-2"
            @click="addMember"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <pre>Miembros: {{ members.length }}</pre>
        <hr>
        <pre>Coinversion: {{ isCoInvestment }}</pre>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead class="blue-grey darken-4">
              <tr class="">
                <th class="white--text">#</th>
                <th class="text-uppercase white--text">
                  Nombre completo
                </th>
                <th class="text-uppercase white--text">
                  Cargo
                </th>
                <th class="text-uppercase white--text">
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in members"
                :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td class="strong">{{ item.nombreCompleto }}</td>
                <td>{{ item.cargoIntegrante }}</td>
                <td>
                  <v-btn
                    :disabled="(item.esMesaDirectiva === true)"
                    class="mx-2"
                    fab
                    light
                    x-small
                    color="pink"
                  >
                    <v-icon dark @click="deleteMember(item.id)" color="white">
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
        
        <v-btn
          v-if="isCoInvestment === true"
          :disabled="(members.length < 15)"
          x-large
          color="red darken-4"
          dark
          class="float-right"
          @click="updateApplication()"
        >
          Guardar miembros (CI)
          <v-icon
            right
            dark
          >
            mdi-content-save
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          :disabled="members.length < 10"
          x-large
          color="red darken-4"
          dark
          class="float-right"
          @click="updateApplication()"
        >
          Guardar miembros
          <v-icon
            right
            dark
          >
            mdi-content-save
          </v-icon>
        </v-btn>

      </v-col>
    </v-row>






  </v-container>
</template>
<script>
import wsMembers from "@/services/members.js";
import wsApplications from "@/services/application"
import wsAddress from "@/services/address.js"
import UserService from '@/services/user.service'
import authHeader from '@/services/auth-header';

import axios from 'axios';

export default {
  name: 'MemberRelationshipComponent',

  data: () => ({
    valid: true,
    dialog: false,
    currentImageNacionalidad: undefined,
    previewImageNacionalidad: undefined,
    responseOk: '',
    errorsUpload: '',
    members: [],
    orgId: [],
    currentOrganization: [],
    
    inputFile: {},
    urlComprobante: '',
    nameComprobante: '',
    selectedItem: [],
    uuidOrganization: '',
    statesBirth: [],
    townshipsBirth: [],

    dataForm:{
      idSolicitud: '',
      nombreCompleto: '',
      stateMember: '', //Relation
      townshipMember:'', //Relation
      comprobanteNacionalidad: null,
      esMesaDirectiva: false,
    },

    rules: {
      required: [
        value => !!value || 'Este campo es requerido'
      ],
      uploadNacionalidad: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!'
      ],
    },

    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },

  }),

  mounted(){
    if(!this.currentUser){
      this.$router.push('/auth/login')
    }

    this.loadStatesBirth(42);
    this.loadTownsBirth( this.dataForm.stateMember ? this.dataForm.stateMember : 0 );
    this.uuidOrganization = this.$route.params.uuid
    this.loadMembers(this.uuidOrganization);

    this.getApplication(this.$localStorage.get("appID"))
    
    this.loadOrganization(this.routeUuid)
  },
    


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    isCoInvestment() {
      if (this.currentOrganization.ambitoActividad) {
        var result = this.currentOrganization.ambitoActividad.includes('coinversion')
        return result;
      }
      return false;
    },

    routeUuid(){
      return this.$route.params.uuid;
    },

    organizationId(){
      return this.currentOrganization.id
    }
  },


  methods:{

    addTmpMember(){
      this.dataStorage = this.dataForm
      console.log("🚀 ~ file: MemberRelationship.vue ~ line 211 ~ addMember ~ this.dataStorage", this.dataStorage)
    },


    
    loadOrganization(uuid){
      wsApplications.findByUuidAndOrganization(uuid).then(
        response => {
          this.currentOrganization = response.data.data.organization
        },
        error => {
          this.currentOrganization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },


    addMember(){
      var dataStorage = {
        application: this.$localStorage.get("appID"),
        nombreCompleto: this.dataForm.nombreCompleto,
        cargoIntegrante: 'miembro',
        esMesaDirectiva: false,
        comprobanteNacionalidad: this.dataForm.comprobanteNacionalidad,
        state: this.dataForm.stateMember,
        township: this.townshipMember
      }
      if(this.$refs.form.validate() === true){
        wsMembers.create({data:dataStorage}).then(
          response => {
            this.representative = response.data.data;
            this.snackbar = true;
            this.$refs.form.reset();
            this.loadMembers(this.$route.params.uuid);
          },
          error => {
            this.representative =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              console.log("🚀 ~ file: boardDirectors.vue:433 ~ addMember ~ this.representative", this.representative)
          }
        )
      }

    },


    getMember(id) {
      wsMembers.get(id)
        .then(response => {
          this.currentMember = response.data.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteMember(id) {
      wsMembers.delete(id)
        .then(response => {
          this.loadMembers(this.uuidOrganization);
          this.snackbarDelete = true
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadMembers(uuid) {
      wsMembers.getMembersByOrganization(uuid)
        .then(response => {
          this.members = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },


    loadStatesBirth(id) {
      wsAddress.getStateByCountry(id)
        .then(response => {
          this.statesBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    loadTownsBirth(id) {
      wsAddress.getTownByState(id)
        .then(response => {
          this.townshipsBirth = response.data.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    infoPreview() {
      this.dialog = true
      this.selectedItem = this.dataForm;
      this.uuidOrganization = this.$route.params.uuid;
    },


    selectImageNacionalidad(image) {
      this.currentImageNacionalidad = image;
      console.log("🚀 ~ file: MemberRelationship.vue ~ line 241 ~ selectImageNacionalidad ~ this.currentImageNacionalidad", this.currentImageNacionalidad)
      this.previewImageNacionalidad = URL.createObjectURL(this.currentImageNacionalidad);
    },

    onUpload(){
      const formData = new FormData()
      formData.append('files', this.currentImageNacionalidad)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.responseOk = response.data[0]
          this.dataForm.comprobanteNacionalidad = this.responseOk.id
          this.urlComprobante = '' + this.responseOk.url
          this.nameComprobante = '' + this.responseOk.hash
          console.log("🚀 ~ file: MemberRelationship.vue ~ line 253 ~ .then ~ this.responseOk", this.responseOk)
          console.log("🚀 ~ file: MemberRelationship.vue ~ line 254 ~ .then ~ this.dataForm.comprobanteNacionalidad", this.dataForm.comprobanteNacionalidad)
          this.clearNacionalidad();
        }).catch((error)=>{
          this.errorsUpload = error
          console.log("🚀 ~ file: MemberRelationship.vue ~ line 255 ~ .then ~ this.errorsUpload", this.errorsUpload)
        })
    },

    clearNacionalidad (){
      this.inputFile = {},
      this.currentImageNacionalidad = undefined;
      this.previewImageNacionalidad = undefined;
    },

    getApplication(id){
      wsApplications.getApplication(id).then(
        response => {
          this.orgId = response.data.data;
          this.$localStorage.set("orgId", JSON.stringify(this.orgId))
        }
      );
    },


    updateApplication(){
      var data = {
        status: {
          rmeData: true,
          endorsement: true,
          endorsementValidation: true,
          organizationData: true,
          representativeData: true,
          boardDirectors: true,
          memberRelationship: true,
          coInvestment: this.isCoInvestment ? true : false,
        }
      }
      wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
        response => {
          this.application = response.data.data;
          
          //if(this.isCoInvestment === true){
          //  this.$router.push('/co-investment/project-data/' + this.$route.params.uuid)
          //}else{
          //  this.$router.push('/dashboard/')
          //}
          this.$router.push('/dashboard/')

        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      )
    },


  },


}
</script>