<template>
  <div class="preview organization-data">
    
    <v-row>
      <v-col>
        <v-simple-table class="table-preview">
          <template v-slot:default>
            <tbody>
              
              <tr class="blue-grey darken-4">
                <td colspan="3" class="text-uppercase text-center">
                  <h1 class="white--text">Datos de Organización</h1>
                </td>
              </tr>

              <tr>
                <td width="33%">
                  <small class="light overline">Fecha de la Solicitud</small><br>
                  <strong>{{ organization.fechaSolicitud }}</strong>
                </td>
                <td width="33%">
                  <small class="light overline">Tipo de Solicitud</small><br>
                  <strong class="text-capitalize">{{ reeplace_str(organization.tipoSolicitud,'-',' ') }}</strong>
                </td>
                <td width="33%">
                  <small class="light overline">Número de Folio</small><br>
                  <strong>{{ organization.numeroFolio }}</strong>
                </td>
              </tr>
              
              <tr>
                <td colspan="2">
                  <small class="light overline">nombre de la Organización</small><br>
                  <strong>{{ organization.nombreOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Número de miembros</small><br>
                  <strong>{{ organization.numeroMiembros }}</strong>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <small class="light overline">Objetivo</small><br>
                  <strong>{{ organization.objetivo }}</strong>
                </td>
                <td class="">
                  <small class="light overline mb-4">Ámbito de actividades</small><br>
                  <ol class=" mb-4">
                    <li v-for="(item,i) in organization.ambitoActividad" :key="i" class="text-capitalize">
                      {{ reeplace_str(item,'-',' ') }}
                    </li>
                  </ol>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Domicilio en el Exterior</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Páis</small><br>
                  <strong>{{ organization.countryExterior.name }}</strong>
                </td>
                <td>
                  <small class="light overline">estado/Provincia</small><br>
                  <strong>{{ organization.stateExterior.name }}</strong>
                </td>
                <td>
                  <small class="light overline">Condado/Distrito</small><br>
                  <strong>{{ organization.townshipExterior.name }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <small class="light overline">Ciudad</small><br>
                  <strong>{{ organization.ciudad }}</strong>
                </td>
                <td colspan="2">
                  <small class="light overline">Calle</small><br>
                  <strong>{{ organization.calle }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <small class="light overline">Código Postal</small><br>
                  <strong>{{ organization.codigoPostal }}</strong>
                </td>
                <td>
                  <small class="light overline">Número Exterior</small><br>
                  <strong>{{ organization.numeroExterior }}</strong>
                </td>
                <td>
                  <small class="light overline">Número Interior</small><br>
                  <strong>{{ organization.numeroInterior }}</strong>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Datos de contacto</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Correo Electrónico</small><br>
                  <strong>{{ organization.emailOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Teléfono Fijo</small><br>
                  <strong>{{ organization.telefonoFijoOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Teléfono Movil</small><br>
                  <strong>{{ organization.telefonoMovilOrganizacion }}</strong>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Redes Sociales</h3>
                </td>
              </tr>

              <tr>
                <td>
                  <small class="light overline">Twitter</small><br>
                  <strong>{{ organization.twitterOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Facebook</small><br>
                  <strong>{{ organization.facebookOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Tiktok</small><br>
                  <strong>{{ organization.tiktokOrganizacion }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <small class="light overline">Instagram</small><br>
                  <strong>{{ organization.instagramOrganizacion }}</strong>
                </td>
                <td>
                  <small class="light overline">Otra red social</small><br>
                  <strong>{{ organization.otraRedOrganizacion }}</strong>
                </td>
                <td>
                </td>
              </tr>

              <tr class="blue-grey darken-2">
                <td colspan="3" class="text-uppercase text-center">
                  <h3 class="white--text">Estatus de 501(c)3 </h3>
                </td>
              </tr>

              <tr>
                <td :colspan="organization.estatus501C3 === false ? '3' : '1'" class="">
                  <small class="light overline">¿La organización cuenta con estatus de 501(c)3 ante el Servicio de Impuestos Internos (IRS) en Estados Unidos?: </small><br>
                  <strong>{{ organization.estatus501C3 === false ? 'NO' : 'SI' }}</strong>
                </td>
                <td v-if="organization.estatus501C3 === true" colspan="2">
                  <small class="light overline">Comprobante de estatus de 501(c)3</small><br>
                  <div v-if="organization.comprobantesEstatus501C3.mime === 'application/pdf'">
                    <a :href="`${organization.comprobantesEstatus501C3.url}`" target="_blank" >
                      {{organization.comprobantesEstatus501C3.name}}
                    </a>
                  </div>
                  <div v-else>
                    <a :href="`${path.storage_files + organization.comprobantesEstatus501C3.url}`" target="_blank" class="orange">
                      <v-img
                        :lazy-src="path.storage_files + organization.comprobantesEstatus501C3.formats.thumbnail.url"
                        :src="path.storage_files + organization.comprobantesEstatus501C3.formats.thumbnail.url"
                        :alt="`${organization.comprobantesEstatus501C3.name}`"
                        :max-height="organization.comprobantesEstatus501C3.formats.thumbnail.height"
                        :max-width="organization.comprobantesEstatus501C3.formats.thumbnail.width"
                      ></v-img>
                    </a>
                  </div>
                  <!--<pre>{{organization.comprobantesEstatus501C3}}</pre>-->
                </td>
              </tr>

            </tbody>
          </template>
        </v-simple-table>


        <pre>{{organization}}</pre>

      </v-col>
    </v-row>


	</div>
</template>
<script>
import wsApplications from '@/services/application'

export default {
  name: 'PreviewOrganizationDataComponent',

  props: {
    appId: String
  },
  
  components: {

  },

  data: () => ({
    organization: [],
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),

  mounted(){
    this.loadOrganization(JSON.parse(this.appId))
    console.log("🚀 ~ file: organizationData.vue:234 ~ created ~ this.appId", this.appId)
  },

  computed: {

  },

  created() {
    
  },

  methods:{
    
    loadOrganization(id){
      wsApplications.getOrganizationFull(id).then(
        response => {
          this.organization = response.data.data[0];
          console.log("🚀 ~ file: organizationData.vue:39 ~ loadOrganization ~ this.organization", this.organization)
        },
        error => {
          this.organization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    reeplace_str(string, rep, repWith) {
      const result = string.split(rep).join(repWith)
      return result; 
    },

  },

}
</script>