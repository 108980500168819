<template>
  <v-footer padless class="verde white--text ">
    <v-col
      class="text-center text-overline light"
      cols="12"
    >
      &copy; <strong>Instituto de los Mexicanos en el Exterior</strong> {{ new Date().getFullYear() }},
      Secretaría de Relaciones Exteriores
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: 'FooterComponent'  
}
</script>