import { render, staticRenderFns } from "./organizationData.vue?vue&type=template&id=818577c4&scoped=true&"
import script from "./organizationData.vue?vue&type=script&lang=js&"
export * from "./organizationData.vue?vue&type=script&lang=js&"
import style0 from "./organizationData.vue?vue&type=style&index=0&id=818577c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "818577c4",
  null
  
)

export default component.exports