<template>
  <v-container>
    <v-row>

      <v-col style="display:none">
        <div ref="htmlToPdf" style="margin: 25px 40px;width:500px;">
          <center>
            <h1>El IME Y EL CONSULADO DE MÉXICO EN {{ userRme.name  }}<br>
            HACEN CONSTAR EL REGISTRO DEL CLUB<br>
            {{ organization.nombreOrganizacion }}</h1>

          </center>

          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <table border="0" style="width: 500px !important; margin-top: 100px';">
            <tr>
              <td width="190" class="">
                <hr>
                 <p>{{ userRme.name  }}</p>
              </td>
              <td width="190" class="">
                <hr>
                <p>Ing. Luis Gutierrez Reyes</p>
              </td>
            </tr>
          </table>


          <table border="0" style="width: 380px !important;">
            <tr>
              <td width="190" class="small">||Firmante:{{organization.nombreOrganizacion}}|61a6d805-72ec-4ccd-b5cd-466c0bbed18f|MXORG103|julietam|federacion|{{organization.nombreOrganizacion}}||</td>
            </tr>
            <tr>
              <td width="190" class="small">fHxGaXJtYW50ZTpDb25zdWxtZXgrT3JsYW5kb3w2MWE2ZDgwNS03MmVjLTRjY2QtYjVjZC00NjZjMGJiZWQxOGZ8TVhPUkcxMDN8anVsaWV0YW18ZmVkZXJhY2lvbnxPcmdhbml6YWNpb24rTWlncmFudGUrZW4rZWwrRXh0ZXJpb3J8fA==</td>
            </tr>
          </table>

        </div>
      </v-col>
      
      <v-col>
        <v-btn x-large color="dorado-obscuro" dark @click="generatePdf(application.uuid)">
          <v-icon left>mdi-file-pdf-box</v-icon>
          Descargar Formato
        </v-btn>

        <pre>{{ userRme }}</pre>
      </v-col>
    
    </v-row>
  </v-container>
</template>
<script>
//import VueHtml2pdf from 'vue-html2pdf'
import { jsPDF } from "jspdf";

import wsAddress from "@/services/address"
import wsOrganization  from "@/services/organizations"
import wsApplications from '@/services/application'
//import PdfComponent from '@/components/previews/pdf.vue'

export default {
  components: {
    //VueHtml2pdf,
    //PdfComponent
  },

  data: () => ({
    application: [],
    organization:{
      townshipExterior: {},
      stateExterior: {},
      countryExterior: {}
    },
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    userProfile(){
      var result = JSON.parse(this.$localStorage.get("userProfile"))
      return result;
    },
    userRme(){
      var result = JSON.parse(this.$localStorage.get("userRme"))
      return result;
    },
    currentOrganization(){
      const result = this.organization.id
      return result;
    },
    routeUuid(){
      return this.$route.params.uuid;
    }
  },

  mounted(){
    this.loadApplication(this.routeUuid)
    //this.loadOrganization(this.appID.id)
    //this.generateReport()
  },

  methods: {

    generatePdf(uuid){
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "letter",
        compress: "true",
        precision: 4,
        putOnlyUsedFonts:true
      });
      //doc.addFont("Montserrat.ttf", "Monserrat", "normal");
      doc.html(this.$refs.htmlToPdf, {
        callback: function (doc) {
          doc.save('constancia_'+ uuid +'.pdf');
        },
        x: 0,
        y: 0
      });


    },



    loadApplication(uuid){
      wsApplications.findByUuidAndOrganization(uuid).then(
        response => {
          this.application = response.data.data;
          this.organization = response.data.data.organization;
        },
        error => {
          this.application =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    loadOrganization(id){
      wsOrganization.get(id).then(
        response => {
          this.organization = response.data.data;
        },
        error => {
          this.organization =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },


  },


}
</script>
<style scoped>
h1 {
  font-size: 12px;
  margin: 20px 0 0;
  font-family: 'Arial Narrow', Arial, sans-serif,verdana, arial, helvetica;
  letter-spacing: 0.025em;
  word-spacing: 0.1em;
}
p{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif,verdana, arial, helvetica;
  font-size: 8px;
  word-spacing: 0.1em;

}
.small{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif,verdana, arial, helvetica;
  font-size: 5px;
  word-spacing: 0.1em;
  letter-spacing: -0.025em;
  display: inline;
  background-color: bisque;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>