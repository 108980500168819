<template>
  <section class="board-directors">

    <span v-switch="user_role">
      <v-btn text to="/admin/dashboard" class="mr-2" v-case="'SysAdmin'">SysAdmin</v-btn>
      <v-btn v-case="'ime_certificador'">ime_certificador</v-btn>
      <v-btn text to="/ime/dashboard" class="mr-2" v-case="'ime_validador'">ime_validador</v-btn>
      <v-btn text to="/ime/dashboard" class="mr-2" v-case="'ime_observador'">ime_observador</v-btn>
      <v-btn v-case="'rme_certificador'" >rme_certificador</v-btn>
      <v-btn v-case="'rme_validador'" >rme_validador</v-btn>
      <CromBoardDirectorsComponent v-case="'organizacion'" ></CromBoardDirectorsComponent>
      <ProgressComponent v-default></ProgressComponent>
    </span>

	</section>
</template>

<script>
import UserService from '@/services/user.service'
import ProgressComponent from '@/components/global/_Progress.vue'
import CromBoardDirectorsComponent from '@/components/roles/organizacion/crom/boardDirectors.vue'

export default {
  name: 'BoardDirectorsView',
  
  components: {
    ProgressComponent,
    CromBoardDirectorsComponent
  },

  data: () => ({
    user_role: '',
    rme_data: {}
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted(){
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

    if(this.$localStorage.get('rmeData')){
      this.rme_data = JSON.parse(this.$localStorage.get('rmeData'))
      this.rme_data_name = this.rme_data.name
    }else{
      console.log('SIN DATOS')
    }


  },

  methods:{
    logOut() {
      this.$localStorage.remove('appID')
      this.$localStorage.remove('img501C3')
      this.$localStorage.remove('imgIden')
      this.$localStorage.remove('imgNac')
      this.$localStorage.remove('orgId')
      this.$localStorage.remove('orgType')
      this.$localStorage.remove('userRole')
      this.$localStorage.remove('rmeData')

      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    }
  },

}
</script>