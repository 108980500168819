<template>
  <v-app>
    <v-app-bar
      app
      color="rojo"
      dark
    >
      <section class="d-flex align-center">
        <a href="/">
          <v-img
            alt="Intituto de los Mexicanos en el Exterior"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://ime.gob.mx/images/ime-mx.png"
            width="100"
          />
        </a>
      </section>

      <v-spacer></v-spacer>

      <MenuRolesComponent v-if="currentUser" />

      <GlobalPublicMenuComponent v-if="!currentUser" />

    </v-app-bar>

    <v-main class="my-7">
      <router-view />
    </v-main>

    <FooterComponent />

  </v-app>
</template>

<script>
import UserService from '@/services/user.service';
import GlobalPublicMenuComponent from '@/components/global/_PublicMenu.vue'
import MenuRolesComponent from '@/components/roles/MenuRoles.vue'
import FooterComponent from '@/views/template/Footer.vue'
export default {
  name: 'App',

  components: {
    GlobalPublicMenuComponent ,
    MenuRolesComponent,
    FooterComponent
  },

  data: () => ({
    user_role: '', // Declarar el metodo
  }),

  computed: {

    /* Comprobamos el inicio de la sesion por medio del Store */
    currentUser() {
      return this.$store.state.auth.user;
    },

  },

  mounted(){
    
    if(this.currentUser) {
      UserService.loadUserRole().then(response => {
        this.user_role = response.data.role.name
      })
    }

  },

  methods: {
  }

};
</script>
