<template>
  <section class="global-alerts">
    <v-alert
      :color="cfgColorBg"
      :class="cfgClass"
      :border="cfgBorder"
      :icon="cfgIcon"
      :elevation="cfgElevation"
      :dark="cfgVariant === 'dark'"
      :light="cfgVariant === 'light'"
      :prominent="cfgProminent === 'true'"
    >
      <span class="strong"> {{cfgMessage}} </span>
    </v-alert>
	</section>
</template>
<script>

export default {
  name: 'GlobalAlertsComponent',
  
  components: {
    
  },

  props:{
    cfgColorBg: {
      type: String,
      default: 'rojo'
    },
    cfgVariant: {
      type: String,
      default: 'dark'
    },
    cfgMessage: {
      type: String,
      default: 'lorem ipsum dolor sit amet consectetur adipiscing elit'
    },
    cfgIcon: {
      type: String,
      default: 'mdi-vuetify'
    },
    cfgClass: {
      type: String,
      default: ''
    },
    cfgBorder: {
      type: String,
      default: 'left'
    },
    cfgElevation: {
      type: Number,
      default: 1
    },
    cfgProminent: {
      type: String,
      default: 'false'
    }
  },

  data: () => ({
    path: {
      base_url: process.env.VUE_APP_BASE_URL,
      endpoint: process.env.VUE_APP_ENDPOINT,
      storage_files: process.env.VUE_APP_STORAGE_FILES,
    },
  }),
  
}
</script>