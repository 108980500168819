<template>
  <span class="organization-menu">
    <v-btn to="/dashboard" text class="mr-1">
      <v-icon left>mdi-sitemap</v-icon> Registro de Organización
    </v-btn>
	</span>
</template>
<script>

export default {
  name: 'MenuOrganizationComponent',
  
  components: {
  },

  data: () => ({

  }),

  mounted(){

  },

  computed: {

  },

  methods:{

  },

}
</script>