<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="display-2">Carta Aval</h1>
        <hr class="red">
      </v-col>
    </v-row>

    <v-row>
      <v-col col md="8" sm="6" xs="12" class="">
        <p class="">Carga el documento en formato PDF o JPG</p>
        <div class="">
          <v-file-input
            label="Carta Aval"
            :rules="rules.uploadFile"
            @change="selectImage"
            v-model="cartaAval"
            filled
            show-size
            accept="image/jpeg,image/png,application/pdf"
            ref="formUpload"
          ></v-file-input>
        </div>
      </v-col>
      <v-col col md="4" sm="6" xs="12" class="">
        <div v-if="previewImage">
          <div v-if="currentImage.type === 'image/jpeg' || currentImage.type === 'image/png'">
            <v-img
              :lazy-src="previewImage"
              :src="previewImage"
              contain
            >
              <v-progress-linear
                v-if="loading === true"
                indeterminate
                striped
                height="100%"
                value="0"
                color="green"
              ></v-progress-linear>
            </v-img>
          </div>
          <div v-else-if="currentImage.type === 'application/pdf'">
            <iframe :src="previewImage" style="height:320px;width:100%;"></iframe> 
          </div>
          <div v-else>OTRO Archivo</div>

          <v-alert
            v-if="alertWarning === true"
            icon="mdi-account"
            type="error"
          >
            El archivo debe ser menos a 2MB
          </v-alert>

          <v-btn
            v-if="alertWarning === false"
            :disabled="loaded"
            class="mx-2 my-2 float-left"
            fab
            dark
            small
            color="yellow darken-4"
            @click="onUpload"
          >
            <v-icon v-if="loading === false" dark>mdi-upload</v-icon>
            <v-progress-circular
              v-if="loading === true"
              icon
              text
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            :disabled="!loaded"
            class="mx-2 my-2 float-right"
            
            dark
            small
            color="red"
            @click="deleteImage(dataForm.cartaAval)"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col col md="12" class="dorado rounded mb-10 py-6 px-6">
        <v-btn
          :disabled="!loaded"
          x-large
          color="red darken-4"
          dark
          class="float-right"
          @click="infoPreview()"
        >
          Enviar Documento
          <v-icon
            right
            dark
          >
            mdi-file-send
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <v-row>
      <v-col>
        <pre class="yellow">{{ loading }}</pre>
        <pre class="green">{{ loaded }}</pre>
        <pre>{{ dataForm }}</pre>
        <pre class="blue">{{ response_image }}</pre>
      </v-col>
    </v-row>

</v-container>
</template>
<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';  
import ProgressComponent from '@/components/global/_Progress.vue'
import wsApplications from '@/services/application'

export default {
  name: 'EndorsementComponent',
  
  components: {
  },

  data: () => ({
    cartaAval: [],
    currentImage: undefined,
    previewImage: undefined,
    uploadFile: false,
    alertWarning: false,
    loading: false,
    loaded: false,
    response_image: '',

    dataForm: {
      cartaAval: null,
    },

    rules: {
      required: [
        value => !!value || 'Este campo es requerido',
      ],
      uploadFile: [
        value => !value || value.size < 2000000 || '¡El documento debe ser menor a 2 MB!',
      ],
    },

  }),

  mounted(){

  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  methods:{

    saveEndorsement(){
      if(this.loaded === true){
        var data = {
          type_rme: this.application.type_rme,
          e_rme: this.application.e_rme,
          organizationType: this.application.organizationType,
          status: {
            rmeData: true,
            organizationData: true
          }
        }
        wsApplications.update(this.$localStorage.get("appID"), {data:data}).then(
          response => {
            this.application = response.data.data;
            this.loading = true
            //this.seedOrganization();
            this.$localStorage.set("orgType", this.application.organizationType)
            this.$router.push('/crom/organization-data/' + this.$route.params.uuid)
          },
          error => {
            this.application =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }
    },

    selectImage(image) {
      this.uploadFile = true;
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      console.log("🚀 ~ file: organizationData.vue:890 ~ selectImage ~ this.$refs.formUpload.validate()", this.$refs.formUpload.validate())
      if(this.$refs.formUpload.validate() === false){
        this.alertWarning = true
      }else{
        this.alertWarning = false
      }
    },

    onUpload(){
      this.loading = true
      const formData = new FormData()
      formData.append('files', this.currentImage)

      axios.post("https://api-coinversion.k0s.ime.red/api/v2/upload", formData, { headers: authHeader() })
        .then((response)=>{
          this.response_image = response.data[0]
          this.dataForm.cartaAval = this.response_image.id
          //this.$localStorage.set("img501C3", JSON.stringify(this.response_image))
          this.loading = false
          this.loaded = true

        }).catch((error)=>{
          this.errorsUpload = error
          this.loaded = false
        })
    },

    clearUpload (){
      this.uploadFile = false;
      this.dataForm.cartaAval = [],
      this.currentImage = undefined;
      this.previewImage = undefined;
      this.loaded = false
    },

    deleteImage(id){
      wsOrganization.deleteFile(id).then(
        response => {
          this.removeImage = response.data.data;
          this.clearUpload()
        },
        error => {
          this.removeImage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            this.loaded = false
        }
      )
    },


  },

}
</script>